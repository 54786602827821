import React, {Component} from 'react';
import Wrapper from '../Wrapper';
import {requestAPI} from '../../Lib/fetch';
import update from 'immutability-helper';

class AccessKeysModal extends Component{
	state = {keys: [], working: false, newKey: ''};

  componentDidMount(){
    requestAPI({url: '/keys/keys'})
    .val(keys => {
      this.setState({keys});
    })
  }

  onCreateKey = (ev) => {
    ev.preventDefault();
    const newKey = this.state.newKey;

    requestAPI({
      url: '/keys/keys',
      body: {key: newKey},
      method: 'POST'
    })
    .val(success => {
      if(success){
        this.setState(update(this.state, {
          keys: {
            $push: [{key: newKey, count: 0}]
          }
        }));
      }
      else{
				//!TODO add error handling
      }
    });
  };

  onDeleteKey = ev => {
    const index = ev.target.getAttribute('data-index');

    requestAPI({
      url: '/keys/' + this.state.keys[index].key,
      method: 'DELETE'
    })
    .val(success => {
      if(success){
        this.setState(update(this.state, {
          keys: {
            $splice: [[index, 1]]
          }
        }));
      }
      else{
				//!TODO add error handling
      }
    })
  };

  updateNewKey = ev => {
    this.setState({newKey: ev.target.value});
  };

  render(){
    let rows = this.state.keys.map((key, index) => (<tr key={key.key}>
      <td>{key.key}</td>
      <td>{key.count}</td>
      <td><button size="sm" data-index={index} onClick={this.onDeleteKey} color="danger">Delete</button></td>
    </tr>));

    return (<Wrapper title="Edit Access Keys" hideOk={true} okDisabled={this.state.working}>
      <p>Edit the access keys used to unlock the website. Please keep them to <b>lower case english characters</b>.</p>
      <table>
        <tbody>

        <tr>
          <th>Key</th>
          <th>Count</th>
          <th>--</th>
        </tr>
        {rows}

        </tbody>
      </table>

      <form onSubmit={this.onCreateKey}>
        <fieldset>
            <input value={this.state.newKey} onChange={this.updateNewKey} />
            <button>Create Key</button>
        </fieldset>
      </form>
    </Wrapper>);
  }
}

export default AccessKeysModal;
