import {addNotification} from './Actions'

//!TODO harder check of whether to pick up notification message
export const applyNotifications = store => next => action => {
	if(action.noNotify)
		return next(action);

	if(action.error){
		store.dispatch(addNotification({message: action.error, type: 'error'}))
	}
	else if(action.success){
		store.dispatch(addNotification({message: action.success, type: 'success'}))
	}
	return next(action)
}
