import React, { Suspense } from "react";
import Wrapper from "../Wrapper";
import { connect } from "react-redux";
import { uploadPost, updatePost } from "../../Actions/Posts";
import { fetchSubscriptionGroups } from "../../Actions/Subscriptions";
import PictureUploaderContainer from "../../Components/PictureUploaderContainer";
import SubscriptionPicker from "../../SubscriptionPicker/SubscriptionPicker";
import TextEditor from "./TextEditor";

import styles from './EditPost.module.css';

/**
 * Generate a random hexadecimal string 32 characters long
 */
function generateRandomID() {
  const ID_LENGTH = 32;
  const CHAR_POOL = "0123456789abcdef";

  let str = "";
  for (let i = 0; i < ID_LENGTH; ++i) {
    str += CHAR_POOL.charAt(Math.floor(Math.random() * CHAR_POOL.length));
  }
  return str;
}

function toInputReadableString(d) {
  return d.getFullYear() + '-' + getNumberAsStringWithPadded0s(d.getMonth()+1, 2)
   + '-' + getNumberAsStringWithPadded0s(d.getDate(), 2)
   + 'T' + getNumberAsStringWithPadded0s(d.getHours(), 2)
   + ':' + getNumberAsStringWithPadded0s(d.getMinutes(), 2);
}

function getNumberAsStringWithPadded0s(num, digits){
  let s = String(num);
  while(s.length < digits){
    s = '0' + s;
  }
  return s;
}

class EditPostModal extends React.Component {
  constructor(props) {
    super(props);

    const { post } = props.options;
    this.state = {
      title: post.title,
      description: post.description,
      subscriptionGroups: [],
      postDate: toInputReadableString(post.postDate != null ? new Date(post.postDate) : new Date()),
      id: post.id || generateRandomID(),
      isNew: !post.id,
      isUploading: false,
    };
  }

  onOk = () => {
    const { updatePost, uploadPost } = this.props;

    const post = {
      title: this.state.title,
      description: this.state.description,
      post_date: new Date(this.state.postDate),
    };

    this.setState({ isUploading: true });

    if (this.state.isNew) {
      post.temp_id = this.state.id;
      post.subscriptionGroups = this.state.subscriptionGroups;

      return uploadPost(post).val(() => {
        this.setState({ isUploading: false });
        return true;
      });
    } else {
      post.id = this.state.id;
      return updatePost(post).val(() => {
        this.setState({ isUploading: false });
        return true;
      });
    }
  };

  componentDidMount() {
    this.props.fetchSubscriptionGroups();
  }

  render() {
    const { title, id, description, isNew, isUploading, postDate } = this.state;

    return (
      <Wrapper
        title={isNew ? "New Post" : `Edit ${title}`}
        okText="Update Post"
        onOk={this.onOk}
        okDisabled={title.length === 0 || isUploading}
        className="edit_post_modal"
        width="90%"
      >
        <Suspense fallback={<i>Loading</i>}>
          <div className={styles.row}>
            <div className={styles.inputContainer}>
              <label>Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
            </div>
            <div className={styles.inputContainer}>
              <label>Post Date</label>
              <input
                type="datetime-local"
                value={postDate}
                onChange={event => this.setState({ postDate: event.target.value })}
              />
            </div>
          </div>
          <label>Description</label>
          <TextEditor
            value={description}
            onChange={(value) => this.setState({ description: value })}
          />
          <PictureUploaderContainer newAlbum={isNew} album={id} />
          {isNew && (
            <>
              <SubscriptionPicker
                onChange={(groups) =>
                  this.setState({ subscriptionGroups: groups })
                }
              />
              <em>Will send notifications to immediate family if nothing is selected</em>
            </>
          )}
        </Suspense>
      </Wrapper>
    );
  }
}

export default connect(null, {
  uploadPost,
  updatePost,
  fetchSubscriptionGroups,
})(EditPostModal);
