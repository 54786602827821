import {ADD_XHR_TICKET, REMOVE_XHR_TICKET} from '../Middleware/xhrTicket';

export default function isLoading(state = [], action) {
    switch(action.type){
        case ADD_XHR_TICKET:
            return [...state, action.id]
        case REMOVE_XHR_TICKET:
            const index = state.findIndex(t => t === action.id)
            let copy = [...state]
            copy.splice(index, 1)
            return copy
        default:
            return state
    }
}