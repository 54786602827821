import React from 'react'
import Wrapper from '../Wrapper'
import {connect} from 'react-redux'
import {deletePost} from '../../Actions/Posts'
import {addNotification} from '../../Notifications/Actions'

const DeletePostModal = ({deletePost, addNotification, options}) => {
	const {post} = options;
	const onOk = () => {
		return deletePost(post)
    .val(success => {
      if(success){
        addNotification({
          type: 'success',
          message: 'Deleted post ' + post.title
        });
      }
      else{
        addNotification({
          type: 'error',
          message: 'Failed to delete post'
        });
      }
      return success;
    });
	};

	return (<Wrapper
		onOk={onOk}
		title="Confirm Post deletion"
		okText="Delete Post">
		<p>Really delete post?
		</p>
		</Wrapper>)
}

export default connect(null, {deletePost, addNotification})(DeletePostModal)


