export const API = (url) => {
  if (/localhost(:[0-9]+)?/.test(window.location.host)) {
    return '//localhost:4000/api' + url;
  } else {
    return '/api' + url;
  }
};

export const isDev = () => {
  return process.env.NODE_ENV !== 'production';
};

const IMAGE_BASE = isDev() ? 'http://localhost:4000/gallery' : '//yangsohn.net/gallery';

class ImageAPI {
  full(url) {
    return `${IMAGE_BASE}/albums/${url}`;
  }

  big(url) {
    return isDev() ? `${IMAGE_BASE}/resizes/${url}` : 
    `${IMAGE_BASE}/big/${url}`;
  }

  thumb(url) {
    return `${IMAGE_BASE}/thumbs/${url}`;
  }

  video(url) {
    return `${IMAGE_BASE}/albums/${url}`;
  }

  videoThumb(url) {
    const filepath = url.replace(/\.[^.]*$/, '_thumb.png');
    return `${IMAGE_BASE}/albums/${filepath}`;
  }

  zip(albumID) {
    return `${IMAGE_BASE}/${albumID}/photos.zip`;
  }
}

export const imageAPI = new ImageAPI();
