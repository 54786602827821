import React from 'react';
import { Photo as TPhoto } from './MediaTypes';
import VideoViewer from '../VideoViewer';
import { imageAPI } from '../Config';

export default function Photo({ className, image }: {
  image: TPhoto
  className?: string,
}) {
  let content;
  if (image.type === 'video') {
    content = <div style={{ display: 'inline-block', width: '300px' }}>
      <VideoViewer
        video={image}
        onLoad={undefined}
        onPlay={undefined}
        minimal={true}
        noPlayOnClick={true}
      />
    </div>;
  } else {
    content = (
      <img src={imageAPI.thumb(image.original_path)} />
    )
  }
  const filename = image.original_path.slice(image.original_path.lastIndexOf('/')+1);

  return <div style={{
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  }} className={className}>
    {filename}
    {content}
  </div>
}