import {requestAPIX} from './Server';

export const LOAD_SUBSCRIPTIONS = 'sync subscription data'
export const FETCH_SUBSCRIPTIONS = 'load subscription data'

export const LOAD_SUBSCRIPTION_GROUPS = 'load subscription_group data'
export const FETCH_SUBSCRIPTION_GROUPS = 'fetch subscription_gorup_data'

export const REMOVE_SUB_FROM_GROUP = 'remove sub from group'
export const ADD_SUB_TO_GROUP = 'add sub to group'

export const DELETE_GROUP = 'delete a group'
export const DELETE_SUB = 'delete sub';

export const CREATE_GROUP = 'create new subscription group';
export const CREATE_SUB = 'create new subscription';

export const TOGGLE_SUB_ENABLED = 'toggle sub status';

function loadSubscriptions(subs){
  return {
    type: LOAD_SUBSCRIPTIONS,
    subs
  }
}

export function fetchSubscriptions(){
  return function(dispatch){
		dispatch(requestAPIX('/subscriptions/subscriptions'))
    .val(json => {
      dispatch(loadSubscriptions(json));
    })
    .or(error=>{
			//!TODO add error notification
    });
  }
}

export function fetchSubscriptionGroups(){
  return function(dispatch){
    dispatch(requestAPIX('/subscriptions/groups'))
    .val(json => dispatch(loadSubscriptionGroups(json)))
    .or(error => {
    })
  }
}

function loadSubscriptionGroups(groups){
  return {
    type: LOAD_SUBSCRIPTION_GROUPS,
    groups
  }
}

function doRemoveSubFromGroup(group, sub){
	return {
		type: REMOVE_SUB_FROM_GROUP,
		group,
		sub
	}
}

// Use end point /edit_subscription_group
// to 
export function removeSubFromGroup(group, sub){
	return function(dispatch){
	  dispatch(requestAPIX({
      url: '/subscriptions/removeFromGroup',
      method: 'POST',
      body: {
        subscriptionId: sub,
        groupId: group
      }
    }))
		.then((done, results)=>{
			dispatch(doRemoveSubFromGroup(group, sub));
			done();
		})
		.or(error => {
			//!TODO replace removing a sub from group
		})
	}
}

// Use /edit_subscription_group end point
// Add a sub to a group
const doAddSubToGroup = (group, sub) => ({
	type: ADD_SUB_TO_GROUP,
	group, sub
});

export function addSubToGroup(group, sub){
	return function(dispatch){
	  return dispatch(requestAPIX({
      method: 'post',
      url: '/subscriptions/addToGroup',
      body: {
        subscriptionId: sub,
        groupId: group
      }
    }))
		.then((done, results)=>{
			dispatch(doAddSubToGroup(group, sub))
			done()
		})
		.or(error => {
			//!TODO error
		})
	}
}

// Use DELETE action on /subscription_group to delete a group
const doDeleteGroup = group => ({ type: DELETE_GROUP, group })

export function deleteGroup(group){
	return function(dispatch){
		return dispatch(requestAPIX({
			url: '/subscription_group',
			method: 'delete',
			body: group
		}))
		.then((done, results)=>{
			dispatch(doDeleteGroup(group))
			done();
		})
		.or(error => {})
	}
}

// Use DELETE action on /subscriptions to delete a subscription
const doDeleteSub = sub => ({ type: DELETE_SUB, sub })

export function deleteSub(sub){
	return function(dispatch){
		return dispatch(requestAPIX({
			url: '/subscriptions',
			method: 'delete',
			body: sub
		}))
		.then((done, results) => {
			dispatch(doDeleteSub())
			done()
		})
		.or(error => {})
	}
}

// Use POST action on /subscription_group to create a subscription
const doCreateGroup = (id, name) => ({ type: CREATE_GROUP, id, name })

export function createGroup(name){
	return function(dispatch){
	  return dispatch(requestAPIX({
      url: '/subscriptions/groups',
      method: 'post',
      body: {name}
    }))
    .then((done, results) => {
			dispatch(doCreateGroup(results.id, name));
			done();
    })
		.or(error => {});
	}
}

const doAddSubscription = (id, email) => ({
  type: CREATE_SUB,
  sub: {
    id, email, enabled: true, groups: []
  }
});

export function addSubscription(email){
  return function(dispatch){
    return dispatch(requestAPIX({
      url: '/subscriptions/subscriptions',
      body: {email},
      method: 'post'
    }))
    .then((done, results) => {
			dispatch(doAddSubscription(results.id, email));
			done();
    })
    .or(error => {});
  }
}

function doToggleEnabled(id, enabled){
  return {
    type: TOGGLE_SUB_ENABLED,
    id,
    enabled
  }
}

export function toggleEnabled(id){
  return function(dispatch){
    return dispatch(requestAPIX(`/subscriptions/toggleSubscription/${id}`))
    .then(function(done, results){
			dispatch(doToggleEnabled(id, results.enabled === 1));
			done();
    })
    .or(function(error){
      // handle error
    });
  }
}
