import {connect} from 'react-redux';
import Reorderer from './Reorderer';
import {saveOrder} from './Actions';

const mapStateToProps = state => ({
	album: state.photos.data.id,
	working: state.photos.isWorking
});

export default connect(mapStateToProps, {saveOrder})(Reorderer);
