import {connect} from 'react-redux'
import PictureUploader from './PictureUploader'
import {uploadPhoto, editPhoto} from '../Actions/Photos'
import {addNotification} from '../Notifications/Actions'

function mapStateToProps(state, props){
	return {
		newAlbum: props.newAlbum,
		album: props.album,
		previews: state.previews
	}
}

const PictureUploaderContainer = connect(mapStateToProps, {submitPicture: uploadPhoto, saveCaption: editPhoto, addNotification})(PictureUploader)
export default PictureUploaderContainer
