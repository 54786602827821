import React from 'react'
import Wrapper from '../Wrapper'
import {connect} from 'react-redux'
import {removeSubFromGroup, addSubToGroup, deleteGroup, deleteSub, createGroup, fetchSubscriptionGroups, fetchSubscriptions, addSubscription, toggleEnabled} from '../../Actions/Subscriptions'
import './style.css';

class EditSubscriptionsModal extends React.Component{
	state = {subscriptionEntry: '', groupEntry: ''};

	renderSubscriptionEditor = (subscription, index) => {
	  let email = <td><a href="#" onClick={this.toggleSubscription} data-subscription={subscription.id} className={'email ' + (subscription.enabled? 'enabled': 'disabled')}>{subscription.email}</a></td>;

    let buttons = [];
    for(let i = 0; i < this.props.groups.length; ++i){
      const group = this.props.groups[i];
      buttons.push(<td key={subscription.id + '_' + group.id}><input type="checkbox" checked={group.id in subscription.groups} onChange={this.handleSubscriptionChange} data-group={group.id} data-subscription={subscription.id} /></td>);
    }

    const className = (index%2 === 0) ? '' : 'even';

	  return <tr key={subscription.id} className={className}>
      {email}
      {buttons}</tr>;
  };

	handleSubscriptionChange = event => {
	  event.preventDefault();
	  const groupId = parseInt(event.target.getAttribute('data-group'), 10);
	  const subscriptionId = parseInt(event.target.getAttribute('data-subscription'), 10);

	  if(event.target.checked){
      this.props.addSubToGroup(groupId, subscriptionId);
    }
    else{
	    this.props.removeSubFromGroup(groupId, subscriptionId);
    }
  };

	toggleSubscription = event => {
	  event.preventDefault();
	  const subscriptionId = parseInt(event.target.getAttribute('data-subscription'), 10);
	  this.props.toggleEnabled(subscriptionId);
  };

	handleNewSubscription = event => {
	  event.preventDefault();
	  this.props.addSubscription(this.state.subscriptionEntry);
  };

	handleNewGroup = event => {
	  event.preventDefault();
	  this.props.createGroup(this.state.groupEntry);
  };

	updateSubscriptionEntry = event => {
	  this.setState({subscriptionEntry: event.target.value});
  };

	updateGroupEntry = event => {
	  this.setState({groupEntry: event.target.value});
  };

  renderGroupHeader(group){
	  return <th key={group.id}>{group.name}</th>
  }

	componentDidMount(){
		this.props.fetchSubscriptions();
		this.props.fetchSubscriptionGroups();
	}

	
	render(){
    let tableHeaders = this.props.groups.map(this.renderGroupHeader);
    let subscriptionEditors = this.props.subscriptions.map(this.renderSubscriptionEditor);

		return (<Wrapper
			title="Edit Subscriptions"
			hideOk={true}
			className="edit_subscriptions_modal">
      <table>
        <tbody>
        <tr>
          <th>Email</th>
          {tableHeaders}
        </tr>

        {subscriptionEditors}

        </tbody>
      </table>


      <form onSubmit={this.handleNewSubscription}>
        <fieldset>
          <input type="text" value={this.state.subscriptionEntry} placeholder="eg123@gmail.com" onChange={this.updateSubscriptionEntry} />
          <button type="submit">Add Subscription</button>
        </fieldset>
      </form>

      <form onSubmit={this.handleNewGroup}>
        <fieldset>
          <input type="text" value={this.state.groupEntry} placeholder="i.e. Family, Church, Basketball, etc..." onChange={this.updateGroupEntry} />
          <button type="submit">Create Group</button>
        </fieldset>
      </form>
			</Wrapper>);
	}
}

function mapStateToProps(state){
	return {
		subscriptions: state.subscriptions.data,
		isFetching: state.subscriptions.isFetching,
    groups: state.subscriptions.groups
	};
}

export default connect(mapStateToProps, {
	removeSubFromGroup,
	addSubToGroup,
	deleteGroup,
	deleteSub,
	createGroup,
	fetchSubscriptions,
	fetchSubscriptionGroups,
  addSubscription,
  toggleEnabled
})(EditSubscriptionsModal)
