import {REQUEST_SERVER, FINISH_SERVER} from '../Actions/Server';

let xhrs = 1

export const ADD_XHR_TICKET = 'initiating new XHR ticket'
export const REMOVE_XHR_TICKET = 'removing XHR ticket'

function addXhrTicket(ticketId) {
  return {
    type: ADD_XHR_TICKET,
    id: ticketId
  }
}

function removeXhrTicket(ticketId) {
  return {
    type: REMOVE_XHR_TICKET,
    id: ticketId
  }
}

const xhrTicket = store => next => action => {
  if (action.type === REQUEST_SERVER){
    action.xhrId = xhrs++
    store.dispatch(addXhrTicket(action.xhrId))
  } else if(action.type === FINISH_SERVER){
    store.dispatch(removeXhrTicket(action.xhr));
  }
  return next(action)
}

export default xhrTicket
