import React from 'react'
import Wrapper from '../Wrapper'
import {connect} from 'react-redux'
import {attemptAuthentication} from '../../Actions/Admin'
import {addNotification} from '../../Notifications/Actions'

class AuthenticateModal extends React.Component{
	state = {working: false}

	onOk = () => {
		const {attemptAuthentication, addNotification} = this.props;
		this.setState({working: true});

    return attemptAuthentication(this.textbox.value)
    .then((done, results) => {
      if(results.success){
        addNotification({
          type: 'success',
          message: 'Successfully authenticated'
        });
				this.setState({working: false});
        done(true);
      }
      else{
        addNotification({
          type: 'error',
          message: 'Failed to authenticate'
        });
				this.setState({working: false});
        done(false);
      }
    })
  }

  componentDidMount(){
    this.textbox.focus()
  }
  
	render(){
    return (
      <Wrapper
        onOk={this.onOk}
				working={this.state.working}
        title="Authenticate"
				isForm
        okText="Authenticate">
				<input type="password" name="password" ref={e => this.textbox = e} />
      </Wrapper>
	  )
  }
}

export default connect(null, {attemptAuthentication, addNotification})(AuthenticateModal)
