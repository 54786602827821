import {combineReducers} from 'redux'
import {INITIATE_LOAD_PHOTO, LOAD_PHOTO_DATA} from '../PhotoViewer/Actions'
import {EDIT_PHOTO_SUCCESS} from '../Actions/Photos'

function isFetching(state = false, action){
	switch(action.type){
		case INITIATE_LOAD_PHOTO: return true
		case LOAD_PHOTO_DATA: return false
		default: return state
	}
}

function data(state = {}, action){
	switch(action.type){
		case LOAD_PHOTO_DATA:
			return Object.assign({}, action.photo)
		case EDIT_PHOTO_SUCCESS:
			if(action.photo.id === state.id){
				return action.photo
			}
			else{
				return state
			}
		default: return state
	}
}


const photo = combineReducers({
	isFetching,
	data
})

export default photo
