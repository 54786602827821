import React from 'react'
import {connect} from 'react-redux'

import {ButtonLink, ButtonGroup} from './Button';
import AdminOnly from '../Containers/AdminOnly';
import {attemptLogout} from '../Actions/Admin'
import {openAuthenticate} from '../Modals/Authenticate/Actions'
import {openEditPostModal} from '../Modals/EditPost/Actions';
import {openSubscribeModal} from '../Modals/SubscribeModal/Actions';
import {openEditSubscriptionsModal} from '../Modals/EditSubscriptions/Actions';
import {openEditKeysModal} from '../Modals/AccessKeys/Actions';

import './Tools.css';

const ToolBarBase = ({
  canAdmin,
  openSubscribeModal, 
  openNewPost, 
  editSubscriptions, 
  editKeys, 
  logout, 
  openAuthenticate}) => (
	<div className="header-bar">
		<div className="header-bar-contents">
			<h1 className="masthead">양손</h1>
			<ButtonGroup className="page-tools">
				<ButtonLink to="/">Home</ButtonLink>
        <ButtonLink to="/media">Media</ButtonLink>
				<button onClick={openSubscribeModal}>Subscribe/Unsubscribe</button>
				<AdminOnly>
					<button onClick={openNewPost}>Post</button>
					<ButtonLink to="/upload_pictures">Add Pictures</ButtonLink>
					<button onClick={editSubscriptions}>Edit Subscriptions</button>
					<button onClick={editKeys}>Edit Keys</button>
					<button onClick={logout}>Logout</button>
				</AdminOnly>
        {canAdmin && (
				<AdminOnly inverse>
					<button onClick={openAuthenticate}>Admin</button>
				</AdminOnly>
        )}
			</ButtonGroup>
		</div>
	</div>);

const mapDispatchToProps = (dispatch)=>{
  return {
    logout: function(){
      dispatch(attemptLogout())
    },
    openAuthenticate: function(){
      dispatch(openAuthenticate())
    },
    openNewPost: function(){
      dispatch(openEditPostModal({
        id: null,
        title: '',
        description: '',
        postDate: null,
      }))
    },
    editSubscriptions: function(){
      dispatch(openEditSubscriptionsModal({

      }))
    },
    openSubscribeModal: function(){
      dispatch(openSubscribeModal());
    },
    editKeys: function(){
      dispatch(openEditKeysModal());
    }
  }
}

const mapStateToProps = (store) => {
  return {
    canAdmin: store.gateway.canAdmin,
  };
}

export const ToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBarBase)
