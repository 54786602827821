import React from 'react'
import {connect} from 'react-redux'
import {closeNotification} from './Actions'
require('./style.css')

class NotificationHubBase extends React.Component{
	render(){
		let notifications = (this.props.notifications || []).map(note=>(
			<Notification note={note}
				key={note.id}
				close={this.props.closeNotification} />
		))
		return <div className="notification_hub">{notifications}</div>
	}
}


function Notification({note, close}){
	const notificationClass = 'notification ' + note.type || ''
	if(note.message.toString) note.message = note.message.toString()
	
	return (<div className={notificationClass}>{note.message}
		<button className="close_button" onClick={()=>close(note)}>&times;</button>
	</div>)
}

const NotificationHub = connect(stateToProps, dispatchToProps)(NotificationHubBase)

function stateToProps(state){
	return {
		notifications: state.notifications
	}
}

function dispatchToProps(dispatch){
	return {
		closeNotification: (note)=>{
			dispatch(closeNotification(note))
		}
	}
}

export default NotificationHub
