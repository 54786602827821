import {MODAL_OPEN, MODAL_CLOSE} from '../Modals/Actions';

const initialState = {
	component: null,
}

const modal = (state = initialState, {type, component, options})=>{
	switch(type){
		case MODAL_OPEN: 
			return {component, options};
		case MODAL_CLOSE:
			return initialState;
		default:
			return state
	}
};

export default modal
