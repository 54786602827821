import React from 'react';
import { useHistory } from 'react-router-dom';

export default function ButtonLink(props: {
	to: string,
	children: React.ReactNode,
	className?: string
}) {
	const { to, children, className } = props;
	const history = useHistory();
	return (<button 
		onClick={() => history.push(to)} 
		className={className || ''}>
			{children}
		</button>);
}
