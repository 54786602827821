import React from 'react';
import './ErrorBox.css';

export const ErrorBox = ({children}) => <div className="alert-box error-box">
	{children}
</div>;

export const InfoBox = ({children}) => <div className="alert-box info-box">
	{children}
</div>;
