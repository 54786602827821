import {NOTIFICATION_ADD, NOTIFICATION_REMOVE} from './Actions'

export default function notifications(state = [], action){
	switch(action.type){
	case NOTIFICATION_ADD:
		return [ ...state, action.note ]
	case NOTIFICATION_REMOVE:
		let index = state.findIndex(note => note.id === action.note.id)
		if(index>=0){
			return [ ...state.slice(0, index), ...state.slice(index+1) ]
		}
		// if not found, return default state
		return state
	default:
		return state
	}
}
