import React from 'react';
import { imageAPI } from '../Config';
import './style.css';
import loadingSVG from './white-loading.svg';
import LineBreakText from '../Components/LineBreakText';

// export type GalleryPhotoType = {
//   id: number,
//   alt?: string,
//   description: string,
//   lastUpdated?: number,
//   original_path: string,
//   type?: 'photo' | 'video',
// };

// type Props = {|
//   className?: string,
//   // render flags
//   hideCaption?: boolean,
//   hideLoading?: boolean,
//   // image to show
//   photo: GalleryPhotoType,
//   showId?: boolean,
//   size: 'full' | 'big' | 'small',
//   handleClick?: () => mixed,
//   onImageLoad?: (SyntheticEvent<HTMLImageElement>) => mixed,
// |};

// type State = {|
//   loaded: boolean,
// |};

export class PhotoViewerBase extends React.Component {
  static defaultProps = { size: 'big', className: '' };
  loadingImage; // : ?Image;

  componentWillMount() {
    const { hideLoading } = this.props;
    if (hideLoading) {
      this.loadImageInBackground(this.getImageURL());
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.hideLoading &&
      newProps.photo.original_path !== this.props.photo.original_path
    ) {
      this.loadImageInBackground(this.getImageURL());
    }
  }

  loadImageInBackground(URL) {
    this.setState({ loaded: false });
    this.loadingImage = new Image();
    this.loadingImage.src = URL;
    this.loadingImage.onload = this.setLoaded;
  }

  setLoaded = () => {
    this.setState({ loaded: true });
  };

  getImageURL() {
    const { size, photo } = this.props;
    switch (size) {
      case 'full':
        return (
          imageAPI.full(photo.original_path) +
          (photo.lastUpdated ? '?' + String(photo.lastUpdated) : '')
        );
      case 'big':
        return (
          imageAPI.big(photo.original_path) +
          (photo.lastUpdated ? '?' + photo.lastUpdated.toString() : '')
        );
      case 'small':
        return (
          imageAPI.thumb(photo.original_path) +
          (photo.lastUpdated ? '?' + photo.lastUpdated.toString() : '')
        );
      default:
        throw new Error('Unknown image size: ' + size);
    }
  }

  chainOnLoad = (e) => {
    if (e.currentTarget.src && this.props.onImageLoad) {
      this.props.onImageLoad(e);
    }
  };

  render(){
    let {className = '', photo, showId, hideCaption, handleClick, hideLoading} = this.props;

    const filename = photo.original_path.slice(photo.original_path.lastIndexOf('/')+1);
    let idHeader = showId ? (<div className="id_header">{decodeURI(filename)}</div>) : null;
    let caption = (hideCaption === true) ? null : <LineBreakText text={photo.description} />;

    const src = this.getImageURL();

    let body;
    if (hideLoading && !this.state.loaded) {
      body = (
        <div className="photo-container">
          <img className="photo" src={loadingSVG} role="presentation" />
        </div>
      );
    } else {
      body = (
        <div className="photo-container">
          <img
            className="photo"
            alt={photo.description}
            src={src}
            onLoad={this.chainOnLoad}
            onClick={handleClick}
          />
        </div>
      );
    }

    return (
      <div className={`photo_viewer ${className}`}>
        {showId && <div className="id_header">{decodeURI(filename)}</div>}
        {body}
        {!hideCaption && <p className="caption">{photo.description}</p>}
      </div>
    );
  }
}

export const ThumbnailViewer = (props) => (
  <PhotoViewerBase {...props} size="small" />
);
export const ResizeViewer = (props) => (
  <PhotoViewerBase {...props} size="big" />
);
