import React, { useMemo } from 'react';

export default function YearSelector(props: {
  onChange: (year: string) => void;
}) {
  const yearOptions = useMemo(() => {
    const options = [];
    const today = new Date().getFullYear();
    for (let year = today; year > 2000; --year) {
      options.push(year.toString());
    }
    return options;
  }, []);

  return (<select onChange={event => {
    props.onChange(event.target.value);
  }} aria-placeholder="Select year">
    {yearOptions.map(year => (<option value={year} key={year}>
      {year}
    </option>))}
  </select>)
}