import React from 'react'
import Loading from './Loading'
import AdminOnly from '../Containers/AdminOnly'
import { ButtonGroup, ButtonLink } from './Button';
import PhotoList from './PhotoList';
import Slideshow from './Slideshow'
import { imageAPI } from '../Config'
import RichText from './RichText';
import { ErrorBox } from './AlertBox';
import Reorderer from '../Reorderer';
import { Route, Switch, Link } from 'react-router-dom';
import './AlbumBrowser.css';

import styles from './AlbumBrowser.module.css';
import { requestAPIP } from '../Lib/fetch';
import { DateDisplay } from './Date';

export default class AlbumBrowser extends React.Component {
  state = {
    slideshowActive: false,
    currentSlide: null,
    links: null,
    isLoadingLinks: false,
  };

  componentDidMount() {
    this.props.loadData(this.props.albumID);
    this.loadLinks();
  }

  componentDidUpdate({ albumID }) {
    if (albumID !== this.props.albumID) {
      this.props.loadData(this.props.albumID);
      this.loadLinks();
    }
  }

  loadLinks() {
    this.setState({
      isLoadingLinks: true,
    });
    requestAPIP({
      url: `/post_info/${this.props.albumID}`
    }).then(results => {
      this.setState({
        isLoadingLinks: false,
        links: {
          previous: results.before,
          next: results.after,
        }
      })
    })
  }

  openSlideshow = (options) => {
    this.setState({
      slideshowActive: true,
      currentSlide: options.start,
      paused: options.paused
    })
  };

  onLeft = () => {
    if (this.state.currentSlide > 0) {
      this.setState({
        currentSlide: this.state.currentSlide - 1
      })
    }
  };

  onRight = () => {
    if (this.state.currentSlide < this.props.album.photos.length - 1) {
      this.setState({
        currentSlide: this.state.currentSlide + 1
      })
    }
  };

  orderAlbum = () => {
    this.props.orderAlbum(this.props.album.id)
  };

  render() {
    if (this.props.error) {
      return this.renderError(this.props.error);
    }

    const { album, isLoading, editPost, deleteAlbum, match } = this.props;
    if (isLoading || !album) {
      return <Loading />;
    }

    const { slideshowActive, currentSlide, slideInterval, paused, links } = this.state;


    let slideshow = null

    if (slideshowActive === true) {
      slideshow = <Slideshow
        toClose={() => this.setState({ slideshowActive: false })}
        photo={album.photos[currentSlide]}
        onLeft={this.onLeft}
        onRight={this.onRight}
        interval={slideInterval}
        paused={paused}
      />
    }

    return (
      <div className="album_browser">
        <div className={styles.header}>
          <div className={styles.nextLink}>
            {links != null && links.next && <><strong>Newer</strong>:{' '}
              <Link to={`/album/${links.next.id}`}>
                {links.next.title}
              </Link>
            </>}
          </div>
          <div className={styles.headerMain}>
            <h2>{album.title}</h2>
            <DateDisplay value={album.start_date} />
          </div>
          <div className={styles.prevLink}>
            {links != null && links.previous && <><strong>Older</strong>: <Link to={`/album/${links.previous.id}`}>
              {links.previous.title}
            </Link></>}
          </div>
        </div>

        <div className="description">
          <RichText text={album.description} />
        </div>

        <ButtonGroup className="album-tools">
          <AdminOnly>
            <ButtonLink to={`/upload_pictures/${album.id}`}>Add pictures to this album</ButtonLink>
            <button onClick={() => editPost(album)}>Edit Album</button>
            <button onClick={() => deleteAlbum(album)}>Delete Album</button>
            <button onClick={this.orderAlbum}>Order Album by EXIF</button>
            <ButtonLink to={match.url + '/reorder'}>Reorder</ButtonLink>
          </AdminOnly>
          <a href={imageAPI.zip(album.id)} className="btn btn-secondary" download><button>Download All ({album.photos.length})</button></a>
          <button onClick={() => this.openSlideshow({ start: 0, paused: false })}>View Slideshow</button>
        </ButtonGroup>
        <Switch>
          <Route exact path={match.url} render={() => <PhotoList photos={album.photos} showPhoto={start => this.openSlideshow({ start, paused: true })} />} />
          <Route path={`${match.url}/reorder`} render={() => <Reorderer photos={album.photos} onSave={this.onSave} />} />
        </Switch>
        <div className="loading_bar">
          {this.props.hasMore && <button className="load_more_button" onClick={this.loadMore}>Load More</button>}
        </div>
        {slideshow}
      </div>
    )
  }

  renderError(error) {
    switch (error) {
      case "NONEXISTENT": return <ErrorBox>This album does not exist!</ErrorBox>;
      default: return <ErrorBox>Error getting album.</ErrorBox>;
    }
  }

  loadPhotoViewer = (start) => {
    this.openSlideshow({ start, paused: true })
  };
}
