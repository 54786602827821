import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {authorize} from '../Actions/Gateway';
import {Redirect} from 'react-router-dom';

class GatewayProcess extends React.Component{
    componentDidMount(){
        if(this.props.ready){
            this.props.authorize(this.props.match.params.apiKey);
        }
    }

    render(){
        if(!this.props.ready){
            return <Fragment>Checking key: {this.props.match.params.apiKey}</Fragment>
        }
        else if(this.props.ready && this.props.authorized){
            return <Redirect to="/" />;
        }
        else{
            return <Fragment>Invalid key</Fragment>;
        }
        
    }
}

const mapStateToProps = state => ({
    ready: state.gateway.ready,
    authorized: state.gateway.authorized
});

export default connect(mapStateToProps, {authorize})(GatewayProcess);