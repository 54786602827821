import React from 'react';
import Loading from './Loading';
import {requestAPI} from "../Lib/fetch"

const Option = ({id, title, date}) => (<option value={id} key={id}>
	{title}
</option>);

class AlbumPicker extends React.Component{
	state = {
		albums: false,
		picked: null
	};

	componentDidMount(){
		this.fetchAlbums();
	}

	fetchAlbums(){
	  requestAPI( '/albums' )
    .val(albums => {
			const picked = this.props.initial || albums[0].id;
      this.setState({albums, picked}, this.chainOnChange);
    });
	}

	render(){
		const {albums, picked} = this.state;

		return <Loading isLoading={!albums}>
				<select className="album-picker" value={picked} onChange={this.handleChange}>
					{albums && albums.map(Option)}
				</select>
		</Loading>;
	}

	handleChange = (ev) => {
		this.setState({picked: ev.target.value}, this.chainOnChange);
	};

	chainOnChange = () => {
	  if(this.props.onChange) this.props.onChange(this.state.picked);
  };
}

export default AlbumPicker
