import {requestAPI} from '../Lib/fetch';

export const BEGIN_SAVE_ORDER = 'begin saving the order of album';
export const SAVE_ORDER_SUCCESS = 'successfully saved order';
export const SAVE_ORDER_ERROR = 'failed to reorder album';

const beginSaveOrder = () => ({type: BEGIN_SAVE_ORDER});
const saveOrderSuccess = (album, order) => ({type: SAVE_ORDER_SUCCESS, album, order});
const saveOrderError = (album, error) => ({type: SAVE_ORDER_ERROR, album, error});

export const saveOrder = (albumID, order) => dispatch => {
	const photos = order.map(p => p.id);

	dispatch(beginSaveOrder());
	return requestAPI({
		url: `/reorder_album/${albumID}`, 
		method: 'POST',
		body: {photos}
	})
	.val(() => {
		dispatch(saveOrderSuccess(albumID, order));
	})
	.or(error => dispatch(saveOrderError(albumID, error)));
}
