import {connect} from 'react-redux'
import AlbumBrowser from '../Components/AlbumBrowser'
import {fetchPhotos, reorderAlbum} from '../Actions/Photos'
import {requestPhoto} from '../PhotoViewer/Actions'
import {openDeletePostModal} from '../Modals/DeletePost/Actions'
import {openEditPostModal} from '../Modals/EditPost/Actions'

const mapStateToProps = (state, ownProps) => {
	return {
		albumID: ownProps.match.params.id,
		album: state.photos.data,
		isLoading: state.photos.isFetching,
		error: state.photos.error,
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		loadData: (album)=>{
			dispatch(fetchPhotos(album))
		},
		loadPhoto: function(id){
			return dispatch(requestPhoto(id))
		},
		deleteAlbum: function(post){
			return dispatch(openDeletePostModal(post))
		},
		editPost: function(post){
			return dispatch(openEditPostModal(post))
		},
		orderAlbum: function(album){
			return dispatch(reorderAlbum(album))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumBrowser)
