import React from 'react'
import {connect} from 'react-redux'
import AdminOnly from '../Containers/AdminOnly'
import {openEditTextModal} from '../Modals/EditText'
import {fetchBanner, editBanner} from './Actions'
import {MarkdownText} from '../Components/LineBreakText'
import './Banner.css';


class Banner extends React.Component{
	checkOk = (val) => {
		this.props.editBanner(val)
		return true
	}

	componentDidMount(){
		this.props.fetchBanner()
	}

	openEditModal = () => {
        this.props.openEditTextModal({
            title: 'Edit Banner Text',
            value: this.props.bannerText,
            onOk: this.checkOk
        });
	};

	render(){
		return (<div className="banner">
			<MarkdownText text={this.props.bannerText} />
			<AdminOnly>
				<button onClick={this.openEditModal}>Edit the Banner</button>
			</AdminOnly>
		</div>)
	}
}

const stp = state => {
	return {
		bannerText: state.banner.text
	}
}

export default connect(stp, {openEditTextModal, fetchBanner, editBanner})(Banner)


