import {POST_UPDATE_SUCCESS} from '../Actions/Posts'
import {
	REQUEST_PHOTOS, 
	RECEIVE_PHOTOS, 
	EDIT_PHOTO_SUCCESS, 
	UPLOAD_PHOTO_SUCCESS, 
	ROTATE_PHOTO, 
	DELETE_PHOTO,
	FETCH_PHOTOS_ERROR
} from '../Actions/Photos'
import {BEGIN_SAVE_ORDER, SAVE_ORDER_SUCCESS, SAVE_ORDER_ERROR} from '../Reorderer/Actions';
import {combineReducers} from 'redux'
import update from 'immutability-helper'

const photos = combineReducers({
	data,
	isFetching,
	isWorking,
	error,
	isDone
})
export default photos

function error(state = null, action) {
	switch (action.type) {
		case FETCH_PHOTOS_ERROR: return action.error;
		default: return false;
	}
}

function data(state = null, action) {
	switch (action.type) {
		case RECEIVE_PHOTOS:
			return action.album
		case POST_UPDATE_SUCCESS:
			return Object.assign({}, state, action.post)
		case UPLOAD_PHOTO_SUCCESS:
			if (state === null) return state;
			else if (state.hasOwnProperty('id') && action.photo === state.id) {
				// weird scenario for this to happen
				return update(state, { photos: { $push: [action.photo] } })
			}
			else {
				return state
			}
		case EDIT_PHOTO_SUCCESS:
			if (state !== null && state.hasOwnProperty('id') && action.photo.album_id === state.id) {
				let index = state.photos.findIndex(photo => action.photo.id === photo.id)
				if (index === -1) throw new Error("Photo not found in matching album")

				return update(state, { photos: { [index]: { $set: action.photo } } })
			}
			else {
				return state
			}
		case ROTATE_PHOTO:
			let index = state.photos.findIndex(photo => action.id === photo.id);
			return update(state,
				{
					photos: {
						[index]: {
							lastUpdated: {
								$set: Date.now()
							}
						}
					}
				});

		case SAVE_ORDER_SUCCESS:
			return update(state, { photos: { $set: action.order } });
		case DELETE_PHOTO:
			{
				let index = state.photos.findIndex(photo => action.id === photo.id);
				return update(state, {
					photos: {
						$splice: [[index, 1]]
					}
				});
			}

		default:
			return state
	}
}

function isFetching(state = false, action) {
	return action.type === REQUEST_PHOTOS
}

function isWorking(state = false, action) {
	switch (action.type) {
		case BEGIN_SAVE_ORDER:
			return true;
		case SAVE_ORDER_SUCCESS:
		case SAVE_ORDER_ERROR:
			return false;
		default: return state;
	}
}

function isDone(state = false, action) {
	switch (action.type) {
		case RECEIVE_PHOTOS:
			return action.album.photos.length === 0;
		default: return state;
	}
}
