import React from 'react'
import AlbumPicker from './AlbumPicker'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PictureUploaderContainer from './PictureUploaderContainer';

export default function UploadPictureForm() {
	const match = useRouteMatch();
	const history = useHistory();
	const album = match.params.album;

	return (
		<div>
			<AlbumPicker onChange={album => history.replace(`/upload_pictures/${album}`)} initial={album} />
			{album && (
				<PictureUploaderContainer album={album} />
			)}
		</div>
	);
}