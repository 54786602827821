// @flow strict
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { imageAPI } from '../Config';
import './Thumbnail.css';

export const THUMBNAIL_DND_TYPE = 'thumbnail';

const ThumbnailSource = {
  beginDrag({ photo, index }) {
    return {
      id: photo.id,
      originalIndex: index,
    };
  },
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const ThumbnailTarget = {
  drop({ photo, position, moveImage, index }, monitor, component) {
    const fromIndex = monitor.getItem().originalIndex;
    moveImage(fromIndex, index, true);
  },

  canDrop(props, monitor) {
    return props.photo.id !== monitor.getItem().id;
  },
};

const dropCollect = (connect, monitor) => {
  const sourceIndex = monitor.getItem() && monitor.getItem().originalIndex;
  return {
    connectDropTarget: connect.dropTarget(),
    hover: monitor.isOver(),
    canDrop: monitor.canDrop(),
    sourceIndex,
  };
};

const MoveableThumbnail = props => {
  const {
    connectDragSource,
    connectDropTarget,
    isDragging,
    photo,
    hover,
    canDrop,
    sourceIndex,
    index,
  } = props;

  let hoverClass = '';
  if (canDrop && hover) {
    hoverClass = sourceIndex < index ? 'hover-right' : 'hover-left';
  }

  const draggingClass = isDragging ? 'dragging' : '';

  return connectDropTarget(
    connectDragSource(
      <div className={hoverClass}>
        <MoveableThumbnailBase
          photo={photo}
          className={`reorder-thumb thumbnail ${hoverClass} ${draggingClass}`}
        />
      </div>
    )
  );
};

const MoveableThumbnailBase = props => {
  const { className, photo } = props;
  if (/\.(mp4|m4v)$/i.test(photo.original_path)) {
    return (
      <div className={`video-thumbnail ${className || ''}`}>
        <img
          src={imageAPI.videoThumb(photo.original_path)}
          role="presentation"
          className="photo"
        />
        <img
          className="video_label"
          src="/images/play.png"
          role="presentation"
        />
      </div>
    );
  }
  return (
    <img
      className={`thumbnail ${className || ''}`}
      src={imageAPI.thumb(photo.original_path)}
      alt={photo.alt}
    />
  );
};

export default DropTarget(THUMBNAIL_DND_TYPE, ThumbnailTarget, dropCollect)(
  DragSource(THUMBNAIL_DND_TYPE, ThumbnailSource, dragCollect)(
    MoveableThumbnail
  )
);
