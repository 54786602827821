import React from 'react';
import {createRoot} from 'react-dom/client';
import AppContainer from './AppContainer';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import {applyNotifications} from './Notifications/Middleware'
import { Provider } from 'react-redux';
import AppReducer from './Reducers';
import {BrowserRouter, Route} from 'react-router-dom';
import xhrTicket from './Middleware/xhrTicket'
import { enableMapSet } from 'immer';

enableMapSet();

let store

if(process.env.NODE_ENV=== 'production'){
  store = createStore(AppReducer, applyMiddleware(
    thunkMiddleware,
    applyNotifications,
    xhrTicket
  ));
}
else{
  store = createStore(AppReducer, applyMiddleware(
    thunkMiddleware,
		//loggerMiddleware,
		applyNotifications,
		xhrTicket
  ));
}

const root = createRoot(
  document.getElementById('root')
);
root.render(
  <Provider store={store}>
		<BrowserRouter>
			<Route component={AppContainer} />
		</BrowserRouter>
	</Provider>
);

