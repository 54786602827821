import React from 'react';
import { imageAPI } from '../Config';
import './PhotoStrip.css';

const Photo = ({ id, original_path: url }) => (
  <img
    key={id}
    src={imageAPI.thumb(url)}
    role="presentation"
    className="photo"
  />
);

const Video = ({ id, original_path: src }) => {
  return (
    <span className="video" key={id}>
      <img
        src={imageAPI.videoThumb(src)}
        role="presentation"
        className="photo"
      />
      <img className="video_label" src="/images/play.png" role="presentation" />
    </span>
  );
};

export class PhotoStrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPhotosShown: props.max || 6,
      page: 0,
    };
  }

  navigateLeft = e => {
    this.setState({ page: this.state.page - 1 });
    e.stopPropagation();
  };

  navigateRight = e => {
    this.setState({ page: this.state.page + 1 });
    e.stopPropagation();
  };

  render() {
    const { numPhotosShown, page } = this.state;
    const { link, photos } = this.props;
    const beginIndex = numPhotosShown * page;
    const endIndex = beginIndex + numPhotosShown;
    const lastPage = Math.ceil(photos.length / numPhotosShown) - 1;

    return (
      <div className="photo_strip">
        {link && (
          <div to={link} className="tag">
            Click for more photos
          </div>
        )}
        <button
          disabled={page === 0}
          className={`navigator_button left ${page === 0 ? 'disabled' : ''}`}
          onClick={this.navigateLeft}
        >
          {'<'}
        </button>
        {photos.slice(beginIndex, endIndex).map(this.renderElement)}
        {
          <button
            disabled={page >= lastPage}
            className={`navigator_button right ${
              page >= lastPage ? 'disabled' : ''
            }`}
            onClick={this.navigateRight}
          >
            {'>'}
          </button>
        }
      </div>
    );
  }

  renderElement(media) {
    switch (media.type) {
      case 'photo':
        return Photo(media);
      case 'video':
        return Video(media);
      default:
        throw new Error('Unknown media type, cannot render');
    }
  }
}
