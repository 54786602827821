import {requestAPIX} from './Server'

export const AUTH_ATTEMPT = 'authenticate attempt'
export const AUTH_SUCCESS = 'authenticate success'
export const AUTH_ERROR = 'authenticate fail'
export const AUTH_LOAD = 'authenticate load'
export const AUTH_LOAD_ERROR = 'error loading auth status'
export const LOGOUT_ATTEMPT = 'logout attempt'
export const LOGOUT_SUCCESS = 'logout success'
export const LOGOUT_ERROR = 'logout fail'

export const attemptAuthentication = password => dispatch => {
	return dispatch(requestAPIX({
		url: '/authenticate',
		method: 'POST',
		body: {password}
	}))
		.then((done, result)=>{
			dispatch(attemptSuccess());
			done(result);
		})
		.or(error => {
			dispatch(attemptError(error));
		})
}

function attemptError(error){
	return {
		type: AUTH_ERROR,
		error
	}
}

function attemptSuccess(){
	return {
		type: AUTH_SUCCESS
	}
}

export function attemptLogout(){
	return dispatch=>{
		return dispatch(requestAPIX({
			url: '/logout',
			method: 'post'
		}))
			.val(result=>{
				dispatch(logoutSuccess())
			})
			.or(error => {
				dispatch(logoutError())
			})
	}
}

function logoutSuccess(){
	return {
		type: LOGOUT_SUCCESS,
		success: 'You have logged out'
	}
}

function logoutError(error){
	return {
		type: LOGOUT_ERROR,
		error: 'Error logging out'
	}
}

function attemptLoad(isAdmin){
	return {
		type: AUTH_LOAD,
		isAdmin
	}
}

function attemptLoadError(error){
	return {
		type: AUTH_LOAD_ERROR,
		error
	}
}

export const checkAuthenticate = () => dispatch => {
	return dispatch(requestAPIX('/checkAuthenticate'))
		.val(result => {
			dispatch(attemptLoad(result.isAdmin===true))
		})
		.or(error => dispatch(attemptLoadError(error)))
}
