import { API } from '../Config';

let ASQ = require('asynquence');

export default function fetchJSON(request) {
	return ASQ().promise(fetch(request))
		.seq(response => {
			if (response.status === 200) {
				return ASQ().promise(response.json());
			}
			else {
				return ASQ.failed("Could not fetch API");
			}
		})
}

export function requestAPI(options) {
	const request = setupRequest(options);
	return fetchJSON(request)
		.then((done, results) => {
			if (results.success === false) {
				done.fail(results.error);
			}
			else {
				done(results);
			}
		});
}

export async function requestAPIP(options) {
	const request = setupRequest(options);
	const response = await fetch(request);
	if (response.status === 200) {
		return await response.json();
	}
	throw new Error('Failed to query. URL: ' + options.url);
}

function setupRequest(options) {
	if (typeof options === 'string') {
		options = { url: options };
	}

	let url = options.url;

	const requestOptions = {
		method: options.method || 'GET',
		mode: 'cors',
		credentials: 'include'
	};

	if (options.body) {
		if(requestOptions.method === 'GET'){
			const urlParams = new URLSearchParams();
			for(const [key, value] of Object.entries(options.body)){
				urlParams.set(key, value);
			}
			url = `${url}?${urlParams.toString()}`;
		} else {
			requestOptions.body = JSON.stringify(options.body);
			requestOptions.headers = new Headers();
			requestOptions.headers.append('Content-Type', 'application/json');
		}
	}
	const request = new Request(API(url), requestOptions);
	return request;
}
