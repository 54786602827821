import {LOAD_RESULT} from '../Actions/Gateway';

const initialState = {
  ready: false,
  authorized: false,
  canAdmin: false,
}

export default function(state = initialState, action){
  switch(action.type){
    case LOAD_RESULT:
      return {
        ready: true,
        authorized: action.authorized,
        canAdmin: action.canAdmin,
      }
    default: return state;
  }
}
