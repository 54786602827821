import React from 'react';
import { requestAPI } from '../../../Lib/fetch';

class UnsubscribeForm extends React.Component {
  state = { email: '' };

  handleChange = (event) => {
    this.setState({ email: event.currentTarget.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    requestAPI({
      url: '/subscriptions/unsubscribe',
      method: 'post',
      body: {
        email: this.state.email,
      },
    });
  };

  render() {
    return (
      <div>
        <p>
          Had enough of us? Use the form below to send a confirmation email for
          unsubscription.
        </p>
        <form method="post" action="" onSubmit={this.handleSubmit}>
          <fieldset>
            <input
              placeholder="Enter email here"
              type="email"
              onChange={this.handleChange}
            />
            <button type="submit">Unsubscribe</button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default UnsubscribeForm;
