import {requestAPI} from '../Lib/fetch'

export const REQUEST_BANNER = 'start loading banner text'
export const REQUEST_SAVE_BANNER = 'start saving banner text'

export const EDIT_BANNER_SUCCESS = 'SUCCESSFULLY EDITTED BANNER'
export const EDIT_BANNER_ERROR = 'error saving banner'
export const FETCH_BANNER_SUCCESS = 'successfully got banner'
export const FETCH_BANNER_ERROR = 'error fetching banner'

const requestFetchBanner = () => ({ type: REQUEST_BANNER })
const fetchBannerSuccess = text => ({ type: FETCH_BANNER_SUCCESS, text })
const fetchBannerError = error => ({ type: FETCH_BANNER_ERROR, error })


export const fetchBanner = () => {
	return dispatch => {
		dispatch(requestFetchBanner());
		return requestAPI({ url: '/banner' })
		.then((done, results)=>{
			if(results.success){
				dispatch(fetchBannerSuccess(results.text))
				done(results.text)
			}
			else{
				dispatch(fetchBannerError(results.error))
				done.fail(results.error)
			}
		})
	}
}


const requestEditBanner = () => ({ type: REQUEST_SAVE_BANNER })
const editBannerSuccess = text => ({ type: EDIT_BANNER_SUCCESS, text })
const editBannerError = error => ({ type: EDIT_BANNER_ERROR, error })

export const editBanner = text => {
	return dispatch => {
		dispatch(requestEditBanner());
		return requestAPI({
      url: '/banner',
      method: 'POST',
      body: {text}
    })
		.then((done, results)=>{
			if(results.success === true){
				dispatch(editBannerSuccess(text))
			}
			else{
				dispatch(editBannerError(results.error))
			}
		})
	}
}

