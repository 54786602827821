import React from 'react'
import {connect} from 'react-redux'
import {closeModal} from './Actions'

const ModalContainer = ({component: Component, closeModal, options }) => {
	return Component !== null ? <Component closeModal={closeModal} options={options} />: null;
};


export default connect(state=>state.modal, {closeModal})(ModalContainer)

