import * as React from 'react';
import Transition from 'react-transition-group/Transition';
import { PhotoViewerBase } from '../PhotoViewer';
import VideoViewer from '../VideoViewer';
import './Slideshow.css';

// type Props = {|
//   interval: number,
//   paused: boolean,
//   photo: GalleryPhotoType,
//   onPause: () => mixed,
//   onPlay: () => mixed,
//   onLeft: () => mixed,
//   onRight: () => mixed,
//   startFullscreen: boolean,
//   toClose: () => mixed,
// |};

// type State = {|
//   isFullscreen: boolean,
//   interval: number,
//   paused: boolean,
// |};
class Slideshow extends React.Component {
  state = {
    isFullscreen: this.props.startFullscreen || false,
    interval: this.props.interval || 5,
    paused: this.props.paused,
  };

  static defaultProps = {
    onRight: () => {},
    onLeft: () => {},
    toClose: () => {},
  };

  switcher;

  onPause() {
    if (this.state.paused) {
      this.switcher = setTimeout(
        this.props.onRight,
        this.state.interval * 1000
      );
      this.setState({ paused: false });
    } else {
      clearTimeout(this.switcher);
      this.setState({ paused: true });
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
    clearTimeout(this.switcher);
  }

  handleKeyPress = (e) => {
    if (e.currentTarget === window) {
      switch (e.key) {
        case 'ArrowRight':
          this.props.onRight();
          break;
        case 'ArrowLeft':
          this.props.onLeft();
          break;
        case ' ':
          this.onPause();
          e.stopPropagation();
          e.preventDefault();
          break;
        case 'Escape':
          this.props.toClose();
          break;
        default:
      }
    }
  };

  getFullscreenElement() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    );
  }

  requestFullscreen(el) {
    if (el.requestFullscreen) el.requestFullscreen();
    else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen();
    else if (el.mozRequestFullScreen) el.mozRequestFullScreen();
    else {
      throw new Error('Unsupported browser for fullscreen');
    }
  }

  exitFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else throw new Error('Unsupported browser for fullscreen');
  }

  switchFullscreen = () => {
    if (!this.getFullscreenElement()) {
      this.requestFullscreen(document.getElementById('slideshow_container'));
      this.setState({ isFullscreen: true });
    } else {
      this.exitFullscreen();
      this.setState({ isFullscreen: false });
    }
  };

  onLoad = () => {
    if (!this.state.paused) {
      clearTimeout(this.switcher);
      this.switcher = setTimeout(
        this.props.onRight,
        this.state.interval * 1000
      );
    }
  };

  lowerInterval = () => {
    if (this.state.interval >= 2) {
      this.setState({ interval: this.state.interval - 1 }, () => {
        clearTimeout(this.switcher);
        this.switcher = setTimeout(
          this.props.onRight,
          this.state.interval * 1000
        );
      });
    }
  };

  raiseInterval = () => {
    this.setState({ interval: this.state.interval + 1 }, () => {
      clearTimeout(this.switcher);
      this.switcher = setTimeout(
        this.props.onRight,
        this.state.interval * 1000
      );
    });
  };

  handleBGClick = (e) => {
    if (e.target === e.currentTarget) {
      this.props.toClose();
    }
  };

  determineMediaType(path) {
    if (/\.(mp4|m4v)$/i.test(path)) return 'video';
    else if (/\.(png|jpe?g)$/i.test(path)) return 'photo';
    else return 'unknown';
  }

  render() {
    const statusText = this.state.paused ? 'Paused' : 'Playing';
    const { onLeft, onRight, photo, toClose } = this.props;
    const { isFullscreen } = this.state;
    let body = null;
    switch (photo.type) {
      case 'photo':
        body = (
          <PhotoViewerBase
            key={photo.id}
            photo={photo}
            className="slideshow_viewer"
            onImageLoad={this.onLoad}
            hideLoading={true}
            size={isFullscreen ? 'full' : 'big'}
          />
        );
        break;
      case 'video':
        body = (
          <VideoViewer
            key={photo.id}
            onLoad={this.onLoad}
            onPlay={() => {
              if (!this.state.paused) {
                this.onPause();
              }
            }}
            video={photo}
          />
        );
        break;
    }

    return (
      <div
        className="slideshow fullscreen"
        id="slideshow_container"
        onClick={this.handleBGClick}
      >
        <Fading className="status">{statusText}</Fading>
        <button className="navigation_button left" onClick={onLeft}>
          &lt;
        </button>
        {body}
        <button className="navigation_button right" onClick={onRight}>
          &gt;
        </button>
        <SlideOut>
          <div className="fullscreen_toggler">
            <div>
              <span className="ey-label">Use spacebar to pause/play</span>
              <button onClick={this.lowerInterval}>-</button>
              <span className="ey-label">
                Transition Delay: {this.state.interval}s
              </span>
              <button onClick={this.raiseInterval}>+</button>
            </div>
            <div>
              <button onClick={this.switchFullscreen}>
                Toggle Fullscreen Mode
              </button>
              <button onClick={toClose}>Exit</button>
            </div>
          </div>
        </SlideOut>
      </div>
    );
  }
}

class Fading extends React.Component {
  state = { opacity: 1.0 };
  interval;

  render() {
    return (
      <div
        className={this.props.className}
        style={{ opacity: this.state.opacity }}
      >
        {this.props.children}
      </div>
    );
  }

  componentDidMount() {
    this.interval = setInterval(this.decrementTimer, 100);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.children !== nextProps.children) {
      this.setState({ opacity: 1.0 });
      clearInterval(this.interval);
      this.interval = setInterval(this.decrementTimer, 100);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  decrementTimer = () => {
    this.setState(prevState => ({ opacity: prevState.opacity - 0.05 }));
    if (this.state.opacity <= 0) {
      clearInterval(this.interval);
    }
  };
}

class SlideOut extends React.Component {
  listener;
  state = { hidden: false };
  timeout;

  handleMouseMove = () => {
    this.setState({ hidden: false });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ hidden: true });
    }, this.props.delay || 4000);
  };

  componentDidMount() {
    this.listener = window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    clearTimeout(this.timeout);
  }

  render() {
    const duration = 500;
    const defaultStyle = {
      transition: `opacity ${duration}ms ease`,
      zIndex: 3,
    };

    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
    };

    return (
      <Transition in={!this.state.hidden} timeout={duration}>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            {this.props.children}
          </div>
        )}
      </Transition>
    );
  }
}

export default Slideshow;
