import { requestAPI, requestAPIP as requestAPIPBase } from '../Lib/fetch';
import { addNotification } from '../Notifications/Actions';
export const REQUEST_SERVER = 'generic server request message'
export const FINISH_SERVER = 'generic finish server request'

/**
 * Generic action creators for creating/finishing XHR requests.
 * Tightly coupled with xhrTicket middleware (generates unique XHR id for each request
 *
 * Message is a optional? (can be null) that is used by the Notification middleware to display appropriate message. Must be an object that complies with notification middleware specs. (success: string or error: string) 
 */

export function requestServer(noNotify) {
	return {
		type: REQUEST_SERVER,
		noNotify,
		xhr: true
	}
}

export function finishServer(xhr, noNotify) {
	return {
		type: FINISH_SERVER,
		noNotify,
		xhr
	}
}

/**
 * Convenience wrapper action creator
 */
export const requestAPIX = options => dispatch => {
	if (typeof options === 'string') options = { url: options };
	const { noNotify, ...xhrOptions } = options;
	const xhr = dispatch(requestServer(noNotify)).xhrId;
	const req = requestAPI(xhrOptions);

  req.fork().then(done => {
    dispatch(finishServer(xhr, noNotify));
    done();
  }).or(error => {
    dispatch(finishServer(xhr, noNotify));
  });

	return req.val(results => {
		if (results.success === false) {
			throw new Error(results.error || 'Server error');
		}
		return results;
	});
}

export async function requestAPIP(options, dispatch) {
	const { noNotify, ...xhrOptions } = options;
	const { xhrId: xhr } = dispatch(requestServer(noNotify));
	try {
		const results = await requestAPIPBase(xhrOptions);
		if (results.success === false) {
			throw new Error('Server returned error. ' + results.error || 'Unknown error');
		}
		return results;
	} catch(e) {
		dispatch(addNotification(e.message));
		throw e;
	} finally {
		dispatch(finishServer(xhr, noNotify));
	}
}
