export const MODAL_OPEN = 'open modal'
export const MODAL_CLOSE = 'close modal'

export const openModal = (component, options) => ({
	type: MODAL_OPEN,
	component,
	options
})

export const closeModal = () => ({
	type: MODAL_CLOSE
})
