import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import { ToolBar } from './Components/Tools'
import NotificationHub from './Notifications/Hub'
import ModalContainer from './Modals/Container'
import PostsListContainer from './Containers/PostsListContainer';
import AlbumBrowserContainer from './Containers/AlbumBrowserContainer';
import UploadPictureForm from './Components/UploadPictureForm';
import GatewayForm from './Gateway/GatewayForm';
import GatewayProcess from './Gateway/GatewayProcess';
import LoadingContainer from './Modals/Loading';
import MediaBrowser from './MediaBrowser/MediaBrowser';
import MediaFolder from './MediaBrowser/MediaFolder';
import MediaListReorderer from './MediaBrowser/MediaListReorderer';
import './App.css'

class App extends React.Component {
  state = { hasError: false };

  componentDidMount() {
    if (!this.props.gateway.ready) {
      this.props.fetchGatewayInfo();
    }
  }

  componentDidUpdate({ gateway }) {
    if (this.props.gateway.authorized && !gateway.authorized) {
      this.props.checkAuthenticate();
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      console.log(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      return <Fragment>
        <h1>Something went wrong</h1>
        <p>Please try refreshing the page.</p>
      </Fragment>
    }
    if (!this.props.gateway.ready) {
      return (<img src="/images/loading.gif" alt="loading" />);
    }
    else if (this.props.gateway.authorized) {
      return (<div className="app">
        <ToolBar />
        <Switch>
          <Route path="/album/:id" component={AlbumBrowserContainer} />
          <Route path="/upload_pictures/:album?" component={UploadPictureForm} />
          <Route path="/media/list/:folder/reorder" component={MediaListReorderer} />
          <Route path="/media/list/:folder" component={MediaFolder} />
          <Route path="/media" component={MediaBrowser} />
          <Route path="/" component={PostsListContainer} />
        </Switch>

        <NotificationHub />
        <ModalContainer />
        <LoadingContainer />
      </div>);
    }
    else {
      return (<Switch>
        <Route path="/:apiKey" component={GatewayProcess} />
        <Route component={GatewayForm} />
      </Switch>)
    }
  }
};

export default App
