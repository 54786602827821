import React from 'react';
import { requestAPI } from '../../../Lib/fetch';
import { addNotification } from '../../../Notifications/Actions';
import { connect } from 'react-redux';

class SubscribeForm extends React.Component {
  state = { email: '' };

  onSubmit = ev => {
    ev.preventDefault();
    requestAPI({
      url: '/subscriptions/subscriptions',
      method: 'POST',
      body: { email: this.state.email },
    }).then((done, results) => {
      // TODO fix this
      // this.props.addNotification({
      //   type: 'success',
      //   message: 'Successfully subscribed with email: ' + this.state.email,
      // });
      done();
    });
  };

  setEmail = ev => {
    this.setState({ email: ev.target.value });
  };

  render() {
    return (
      <div>
        <p>
          Subscribe to receive email notifications whenever a new blog item is
          posted. You can unsubscribe any time if you want to cancel.
        </p>
        <form onSubmit={this.onSubmit}>
          <fieldset>
            <input
              placeholder={'Enter email here'}
              type="email"
              value={this.state.email}
              onChange={this.setEmail}
            />
            <button type="submit">Subscribe</button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default connect(
  null,
  { addNotification }
)(SubscribeForm);
