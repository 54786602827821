import photos from './Photos'
import posts from './Posts'
import isAdmin from './isAdmin'
import isLoading from './isLoading'
import {combineReducers} from 'redux'
import notifications from '../Notifications/Reducer'
import photo from './Photo'
import modal from './Modal'
import banner from './Banner'
import {subscriptions} from './subscriptions'
import gateway from './Gateway';

const AppReducer = combineReducers({
	photos,
	posts,
	isLoading,
	isAdmin,
	notifications,
	photo,
	modal,
	banner,
	subscriptions,
	gateway
});

export default AppReducer

