import React, { Dispatch, SetStateAction, useState } from 'react';
import { produce } from 'immer';
import { Folder, Photo as TPhoto } from './MediaTypes';

import MediaItemBrowser from './MediaItemBrowser';
import Photo from './Photo';

import styles from './AdminBrowser.module.css';
import { requestAPIP } from '../Lib/fetch';

interface Props {
  folder: Folder,
  setFolder: Dispatch<SetStateAction<Folder | null>>,
}

export default function AdminBrowser({ folder, setFolder }: Props) {
  const [selected, setSelected] = useState(new Set<TPhoto>());
  const [isAdding, setIsAdding] = useState(false);

  return <>
    <MediaItemBrowser
      renderImage={photo => {
        const isAdded = folder.photos.some(haystack => haystack.id === photo.id);
        return (<SelectablePhoto
          isAdded={isAdded}
          image={photo}
          key={photo.id}
          onSelect={() => {
            const isSelected = selected.has(photo);
            setSelected(
              produce(selected => {
                isSelected ? selected.delete(photo) : selected.add(photo)
              })
            )
          }
          }
          isSelected={selected.has(photo)}
        />)
      }}
    />
    {
      selected.size > 0 && (
        <>
          <div className={styles.spacer} />
          <div className={styles.footer}>
            <p>{selected.size} items selected.</p>
            <button onClick={() => {
              setIsAdding(true);
              addPhotosToFolder(
                folder.id,
                Array.from(selected).map(photo => photo.id),
              ).then(() => {
                setFolder(
                  produce(folder => {
                    folder!.photos.push(
                      ...Array.from(selected)
                    );
                  })
                );
                setSelected(new Set());
              }).finally(() => setIsAdding(false));
            }} disabled={isAdding}>
              Add to list
            </button>
            <button onClick={() => setSelected(new Set())}>
              Cancel
            </button>
          </div>
        </>
      )
    }
  </>
}

function SelectablePhoto({ image, isAdded, isSelected, onSelect }: {
  image: TPhoto,
  onSelect: () => void,
  isAdded: boolean,
  isSelected: boolean,
}) {

  return <div onClick={() => {
    if (!isAdded) {
      onSelect();
    }
  }} className={
    isAdded ? styles.disabledPhoto :
      isSelected ? styles.selected : styles.unselected
  }>
    {image.type === 'video' && !isAdded && (
      <button className={styles.videoSelector} onClick={onSelect}>{isSelected ? 'Remove' : 'Add'}</button>
    )}
    <Photo image={image} className={styles.photo} />
  </div>
}

function addPhotosToFolder(folderID: number, photoIDs: Array<number>) {
  return requestAPIP({
    url: '/media/add_photos_to_folder',
    body: {
      folderID,
      photoIDs,
    },
    method: 'POST'
  });
}
