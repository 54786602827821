import React from 'react';

const CKEditor = React.lazy(() => import('ckeditor4-react'));

/**
 * Rich text editor component that wraps CKEditor
 */
function TextEditor({
  value, 
  onChange, 
}){
  return <CKEditor data={value} onChange={e => onChange(e.editor.getData())} />;
}

export default TextEditor;