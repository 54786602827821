import React, { useEffect, useState } from 'react';
import { requestAPIP } from '../Actions/Server';
import { useDispatch } from 'react-redux';
import { Photo } from './MediaTypes';

import YearSelector from './YearSelector';

import styles from './MediaItemList.module.css';

export default function MediaItemBrowser(
  props: {
    renderImage: (image: Photo) => React.ReactNode,
  }
) {
  const dispatch = useDispatch();
  const [images, setImages] = useState<Array<Photo> | null>(null);
  const [photoYear, setPhotoYear] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (photoYear != null) {
      requestAPIP({
        url: '/media/photos',
        body: { year: photoYear }
      }, dispatch)
        .then(({ photos }) => {
          setImages(photos);
        })
    }
  }, [photoYear]);
  useEffect(() => {
    requestAPIP({
      url: '/media/photos',
      body: { uptoID: 0 }
    }, dispatch)
      .then(({ photos }) => {
        setImages(photos);
      })
  }, []);

  return (<section>
    <h2>Media from the server</h2>
    Jump to year <YearSelector onChange={setPhotoYear} />
    {images != null && <div>
      {addYearShimsAndMap(images, props.renderImage)}</div>
    }
    <button onClick={() => {
      const lastImageID = images != null ? images[images.length - 1].id : 0;
      requestAPIP({
        url: '/media/photos',
        body: { uptoID: lastImageID }
      }, dispatch)
        .then(({ photos }) => {
          setImages([...(images || []), ...photos]);
        })
    }}>Load more</button>
  </section>);
}


function addYearShimsAndMap(
  images: Array<Photo>,
  renderImage: (image: Photo) => React.ReactNode
) {
  const els: Array<React.ReactNode> = [];
  let latestYear: null | number;
  let latestAlbum: null | { id: number, title: string };
  let section = 0;
  images.forEach(image => {
    const uploadedOn = new Date(image.uploaded_at);
    latestYear = uploadedOn.getFullYear();
    if (latestAlbum == null && image.album_id != null ||
      latestAlbum != null && image.album_id == null ||
      latestAlbum!.id !== image.album_id
    ) {
      latestAlbum = image.album_id == null ? null : {
        id: image.album_id,
        title: image.post_title,
      }
      els.push(<div className={styles.separatorContainer} key={String(latestYear) + String(latestAlbum?.id)}>
        <div
          className={styles.yearSeparator}
          key={latestYear}>
          {latestYear}
        </div>
        <div className={styles.albumSeparator}
          key={section++}>
          {latestAlbum != null ? latestAlbum.title : 'No album'}
        </div>
      </div>

      )
    }
    els.push(
      renderImage(image)
    )
  });
  return els;
}



