import {combineReducers} from 'redux'
import {REQUEST_POSTS, RECEIVE_POSTS, ADD_POST, POST_DELETE, POST_UPDATE_SUCCESS} from '../Actions/Posts'
import {EDIT_PHOTO_SUCCESS, UPLOAD_PHOTO_SUCCESS} from '../Actions/Photos'
import update from 'immutability-helper'

const posts = combineReducers({
	data,
	isFetching,
	isDone
});

export default posts

function data(state = [], action){
	switch(action.type){
	case RECEIVE_POSTS:
    return [...state, ...action.posts]
	case ADD_POST:
    return [action.post, ...state]
	case UPLOAD_PHOTO_SUCCESS:
		return registerPhoto(state, action)
	case EDIT_PHOTO_SUCCESS:
		return updatePhoto(state, action.photo)
	case POST_DELETE: 
		return deletePost(state, action.post)
	case POST_UPDATE_SUCCESS:
		return updatePost(state, action.post)
	default:
		return state
	}
}

function updatePhoto(posts, photo){
	const postIndex = posts.findIndex(post => post.id === photo.album_id)
	if(postIndex === -1){
		return posts
	}

	const photoIndex = posts[postIndex].photos.findIndex(p => p.id === photo.id)
	if(photoIndex === -1){
		throw new Error('Photo not found in album')
	}
  
  return update(posts, {[postIndex]: {photos: {[photoIndex]: {$merge: photo}} }})
}

function updatePost(posts, post){
	const index = posts.findIndex(p=>p.id===post.id)
	if(index === -1){
		// edited post isnt in cache, ignore
		return posts
	}
  return update(posts, {[index]: {$merge: post}})
}

function deletePost(posts, postToDelete){
	let i = posts.findIndex(post => post.id === postToDelete.id)
	if(i !== -1){
		return [...posts.slice(0, i), ...posts.slice(i+1)]
	}
	else{
		return posts
	}
}

function registerPhoto(state, action){
	if(action.photo.isNewAlbum){
		// ignore it, not in posts
	}
	else{
		// insert photo into posts collection data if loaded
		// otherwise we can ignore, and the next fetch will retrieve updated photo records
		let ownerIndex = state.findIndex(post => post.id === action.photo.album_id)
		if(ownerIndex !== -1){
      return update(state, {[ownerIndex]: {photos: {$push: [action.photo]}}})
		}
		else{
			return state
		}
	}
}

function isFetching(state = false, action){
	switch(action.type){
	case REQUEST_POSTS:
		return true
	case RECEIVE_POSTS:
		return false
	default: 
		return state
	}
}

// Reducer to keep track of whether the posts are done loading
// Set to true when receiving a RECEIVE_POSTS with 0 posts loaded
function isDone(state = false, action){
	switch(action.type){
		case RECEIVE_POSTS: 
			return action.posts.length === 0;
		default: return state;
	}
}
