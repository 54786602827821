import {REQUEST_BANNER, REQUEST_SAVE_BANNER, EDIT_BANNER_SUCCESS, FETCH_BANNER_SUCCESS} from '../Banner/Actions'

const initialState = {
	text: '',
	isLoading: false
}

const banner = (state = initialState, action) => {
	switch(action.type){
		case REQUEST_BANNER:
			return { isLoading: true }
		case REQUEST_SAVE_BANNER:
			return {isLoading: true}
		case EDIT_BANNER_SUCCESS:
			return {
				isLoading: false,
				text: action.text
			}
		case FETCH_BANNER_SUCCESS:
			return {
				isLoading: false,
				text: action.text
			}
		default: return state
	}
}

export default banner
