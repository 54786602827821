import {connect} from 'react-redux';
import PostsList from '../Components/PostsList'
import {fetchNextPage} from '../Actions/Posts'
import {openDeletePostModal} from '../Modals/DeletePost/Actions'
import {openEditPostModal} from '../Modals/EditPost/Actions'

const mapStateToProps = (state) => {
	return {
		posts: state.posts.data,
		isFetching: state.posts.isFetching,
		hasMore: !state.posts.isDone
	}
}

const PostsListContainer = connect(
	mapStateToProps,
	{ 
		loadNext: fetchNextPage,
		deletePost: openDeletePostModal, 
		editPost: openEditPostModal 
	}
)(PostsList)

export default PostsListContainer


