import React from 'react';
import MoveableThumbnail from './Thumbnail';
import './Reorderer.css';
import {DragDropContext} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {InfoBox} from '../Components/AlertBox';
import {WorkButton, ButtonGroup} from '../Components/Button';

class Reorderer extends React.Component{
	state = {
		photos: this.props.photos,
		isDragging: false,
		changes: [],
	}

	render(){
		const {photos, changes} = this.state;
		return <div className='reorder-wrapper'>
			<InfoBox>Drag and drop to re-order images.</InfoBox>

			<ButtonGroup>
				<WorkButton working={this.props.working} onClick={this.save} disabled={!changes.length}>Save</WorkButton>
				<button onClick={this.undo} disabled={!changes.length}>Undo {changes.length? `(${changes.length})` : null}</button>
			</ButtonGroup>

			<div className="thumbnails">
				{photos.map((photo, i) => <MoveableThumbnail photo={photo}
					key={i} index={i} moveImage={this.moveImage} />)}
			</div>
		</div>;
	}

	moveImage = (from, to) => {
		const photo = this.state.photos[from];
		this.setState(update(this.state, {
			photos: {
				$splice: [
					[from, 1],
					[to, 0, photo]
				]
			},
			changes: { $push: [{from, to}] }
		}));
	};

	undo = () => {
		const {photos, changes} = this.state;
		const change = changes[changes.length-1];
		const photo = photos[change.to];

		this.setState(update(this.state, {
			photos: {
				$splice: [
					[change.to, 1],
					[change.from, 0, photo]
				]
			},
			changes: { $splice: [[ changes.length-1, 1 ]] }
		}));
	};

	save = () => {
		this.props.saveOrder(this.props.album, this.state.photos)
			.then((done, results) => {
				this.setState({
					changes: []
				});
			});
	};
}

export default DragDropContext(HTML5Backend)(Reorderer);
