import fetchJSON from '../Lib/fetch'
import {API} from '../Config'

export const INITIATE_LOAD_PHOTO = 'start loading photo'
export const LOAD_PHOTO_DATA = 'load fetched photo data'

export function initiateLoadPhoto(){
	return {
		type: INITIATE_LOAD_PHOTO
	}
}

export function loadPhoto(photo){
	return {
		type: LOAD_PHOTO_DATA,
		photo
	}
}

export function requestPhoto(id){
	return function(dispatch){
		dispatch(initiateLoadPhoto())
		return fetchJSON(new Request(API(`/photo/${id}`), {
			method: 'get',
			mode: 'cors'
		}))
		.then((done, results)=>{
			if(results.id){
				// success
				dispatch(loadPhoto(results))
			}
			done()
		})
	}
}
