import React from 'react';
import update from 'immutability-helper';
import ButtonGroup from './ButtonGroup';

class ButtonStrip extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectionState: new Array(props.options.length).fill(false)
    };
  }

  componentWillReceiveProps({options}){
    // only load props on initial mount
    if(this.props.options && this.props.options.length > 0) return;
    this.setState({
      selectionState: new Array(options.length).fill(false)
    })
  }

  handleClick = (e) => {
    const {options, onChange} = this.props;

    const optionIndex = parseInt(e.target.value, 10);
    this.setState(update(this.state, {
      selectionState: {
        [optionIndex]: {
          $set: !this.state.selectionState[optionIndex]
        }
      }
    }), () => {
      if(onChange)
        onChange(
          this.state.selectionState
          .map((val, i) => (val ? options[i].id : false))
          .filter(value => value)
        );
    });
  };

  render(){
    const {options} = this.props;
    const {selectionState} = this.state;

    let buttons = options.map((option, index)=> (<button
      key={index}
      className={"button" + (selectionState[index]? ' active' : '')}
      value={index}
      onClick={this.handleClick}
      >
      <input type="checkbox" value={index} checked={selectionState[index]} />
      {option.description}
      </button>)
    );

    return <ButtonGroup className="button_strip">{buttons}</ButtonGroup>;
  }
}

export default ButtonStrip
