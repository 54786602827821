import { connect } from 'react-redux'
import React from 'react'
import './Loading.css'
import loadingSVG from './gear-small.svg';

const LoadingBase = ({ jobs }) => {
	if (jobs.length) {
		return <div className="loading-box">
			<img role="presentation" alt="Loading..." src={loadingSVG} />
		</div>
	}
	return null
}

const mapStateToProps = state => ({
	jobs: state.isLoading
})

export default connect(mapStateToProps)(LoadingBase)
