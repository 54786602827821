import {AUTH_SUCCESS, AUTH_LOAD, LOGOUT_SUCCESS} from '../Actions/Admin.js'

export default function isAdmin(state = false, action){
	switch(action.type){
	case AUTH_SUCCESS:
		return true
	case AUTH_LOAD:
		return action.isAdmin
	case LOGOUT_SUCCESS:
		return false
	default:
		return state
	}
}
