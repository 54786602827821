import { API } from '../Config';
import { requestAPIX } from './Server';
import { networkError } from './Error';
import ASQ from 'asynquence';

export const REQUEST_PHOTOS = 'PHOTOS_REQUEST';
export const RECEIVE_PHOTOS = 'PHOTOS_RECEIVE';
export const UPLOAD_PHOTO = 'photos upload';
export const UPLOAD_PHOTO_SUCCESS = 'photos upload success';
export const FETCH_PHOTOS_ERROR = 'failed to fetch photos';

export const OPEN_SHOW_PHOTO = 'open show photo';
export const CLOSE_SHOW_PHOTO = 'close show photo';

export const EDIT_PHOTO_REQUEST = 'photo edit request';
export const EDIT_PHOTO_SUCCESS = 'photo edit success';

export const DELETE_PHOTO = 'delete photo';
export const REQUEST_DELETE_PHOTO = 'request delete photo';

export const ROTATE_PHOTO = 'rotate photo';
export const REQUEST_ROTATE_PHOTO = 'request rotate photo';

export const REORDER_ALBUM_FAIL = 'failed to reorder album';

function editPhotoSuccess(photo) {
  return {
    type: EDIT_PHOTO_SUCCESS,
    photo,
    success: 'Saved changes to photo',
  };
}

export const editPhoto = photo => dispatch => {
  return dispatch(
    requestAPIX({
      url: '/edit_photo',
      method: 'post',
      body: photo,
    })
  )
    .val(results => {
      dispatch(editPhotoSuccess(photo));
      return results;
    })
    .or(err => dispatch(networkError(err)));
};

const receivePhotos = (album, json) => ({ type: RECEIVE_PHOTOS, album: json });

export const fetchPhotos = (album, force) => (dispatch, getState) => {
  // check if in cache already
  if (!force) {
    const post = getState().posts.data.find(p => p.id === album);
    if (post) {
      return dispatch(receivePhotos(album, post));
    }
  }

  return dispatch(requestAPIX({ url: `/album/${album}` }))
    .val(results => {
      dispatch(receivePhotos(album, results));
      return results;
    })
    .or(error => dispatch(networkError(`Could not fetch album id: ${album}`)));
};

export const uploadPhotoSuccess = photo => ({
  type: UPLOAD_PHOTO_SUCCESS,
  photo,
});

export function uploadPhoto(photo, onProgress) {
  return dispatch => {
    return ASQ(function(done) {
      let uploadTarget;

      switch (photo.get('type')) {
        case 'photo':
          uploadTarget = API('/upload_photo');
          break;
        case 'video':
          uploadTarget = API('/upload_video');
          break;
        default:
          throw new Error('Passed in invalid media type for upload');
      }

      let req = new XMLHttpRequest();
      req.withCredentials = true;

      req.upload.addEventListener('progress', onProgress);

      req.addEventListener('load', function() {
        const response = JSON.parse(this.responseText);
        if (response.success === true) {
          //console.log('Successfully uploaded item: ' + response.item);
          done(response.item);
        } else {
          done.fail(response.error);
        }
      });

      req.addEventListener('error', function(error) {
        done.fail(error);
      });

      req.open('POST', uploadTarget);
      req.send(photo);
    })
      .then((done, responsePhoto) => {
        dispatch(uploadPhotoSuccess(responsePhoto));
        done(responsePhoto);
      })
      .or(error => {
        dispatch(networkError(error));
      });
  };
}

const confirmDelete = id => ({
  type: DELETE_PHOTO,
  id,
  success: 'Successfully removed photo: ' + id,
});

export function deletePhoto(id) {
  return function(dispatch) {
    return dispatch(
      requestAPIX({
        url: `/photo/${id}`,
        method: 'delete',
      })
    )
      .val(results => dispatch(confirmDelete(id)))
      .or(error =>
        dispatch(
          networkError(`Failed to delete photo (${id}): ${error.toString()}`)
        )
      );
  };
}

function confirmRotate(id, angle) {
  return { type: ROTATE_PHOTO, id, angle, success: 'Rotated picture' };
}

export function rotate(id, angle) {
  return function(dispatch) {
    return dispatch(
      requestAPIX({
        url: `/rotate_photo/${id}`,
        method: 'post',
        body: { angle },
      })
    )
      .val(results => dispatch(confirmRotate(id, angle)))
      .or(error =>
        dispatch(
          networkError(`Failed to rotate photo (${id}): ${error.toString()}`)
        )
      );
  };
}

export function reorderAlbum(album) {
  return function(dispatch) {
    return dispatch(
      requestAPIX({
        url: `/reorder_album_exif/${album}`,
      })
    )
      .val(results => dispatch(fetchPhotos(album, true)))
      .or(error =>
        dispatch(
          networkError(`Failed to reorder album ${album}: ${error.toString()}`)
        )
      );
  };
}
