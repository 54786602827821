import React from 'react';
import Loading from './Loading';
import Banner from '../Banner';
import PostView from './PostView';
import './PostsList.css';

class PostsList extends React.Component{
	render(){
    const {
      posts, 
      isFetching, 
      loadNext, 
      hasMore
    } = this.props;
    let splitPosts = this.splitByKey(
      posts, 
      post => new Date(post.start_date).getFullYear()
    );
		let splitPostsViews = splitPosts.map(this.renderGroup);

		return <div>
			<Banner/>
			{splitPostsViews}
			<Loading isLoading={isFetching}>
        {hasMore ? 
          <button onClick={loadNext}>More News...</button> : 
          null
        }
			</Loading>
			</div>

	}

	onScroll = (e) => {
		if(this.props.postsLeft && (window.innerHeight+window.scrollY) >= document.body.offsetHeight){
			this.props.loadNext()
		}
	}

	componentDidMount(){
		window.addEventListener('scroll', this.onScroll)
		if(!this.props.posts.length && !this.props.isFetching){
			this.props.loadNext();
		}
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.onScroll)
	}

	renderPost = ({id, title, start_date, description, photos}) => {
		return <PostView
			key={id}
			id={id}
			title={title}
			start_date={start_date}
			description={description}
			deletePost={this.props.deletePost}
			editPost={this.props.editPost}
			photos={photos}
			/>
	}

	renderGroup = (group) => {
		let header = group.key;
		let posts = group.posts.map(this.renderPost);
		return (<div className="post_group" key={header}>
      <div className="header">
        {header}
      </div>	
			{posts}
		</div>);
	}


	splitByKey(posts, getKey){
		if(!posts.length) return []
		let result = posts.reduce((groups, post) => {
			const latest = groups[groups.length-1]
			if(getKey(post) === latest.key){
				latest.posts.push(post)
			}
			else{
				groups.push({
					key: getKey(post),
					posts: [ post ]
				})
			}
			return groups
		}, [{ key: getKey(posts[0]), posts: []}])
		return result
	}
}

export default PostsList;

