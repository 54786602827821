import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Folder, Photo as TPhoto } from './MediaTypes';
import { useDispatch } from 'react-redux';
import { requestAPIP } from '../Actions/Server';
import Loading from '../Components/Loading';
import Reorderer from '../Reorderer/Reorderer';

interface RouteParams {
  folder: string,
}

export default function MediaListReorderer() {
  const history = useHistory();
  const [folder, setFolder] = useState<Folder | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { folder: folderID } = useParams<RouteParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && folder == null) {
      setIsLoading(true);
      requestAPIP({ url: `/media/folder/${folderID}` }, dispatch)
        .then(response => {
          setFolder({
            ...response.folder,
            photos: response.photos,
          });
        }).finally(() => { setIsLoading(false) });
    }
  }, [isLoading]);
  if (isLoading && folder == null) {
    return <Loading>Loading folder information</Loading>;
  }
  if (folder == null) {
    return <div>Failed to load folder information</div>;
  }
  return <div>
    <button onClick={() => history.goBack()}>Return</button>
    <Reorderer
      photos={folder.photos}
      album={0}
      saveOrder={async (_: any, photos: any) => {
        try {
          setIsLoading(true);
          await requestAPIP({
            url: '/media/folder/save_order',
            method: 'POST',
            body: {
              folderID: folder.id,
              photos,
            }
          }, dispatch);

        } finally {
          setIsLoading(false);
        }
      }}
    />
  </div>;
}

async function saveOrder(_: any, photos: Array<TPhoto>) {
  return await requestAPIP(

  )
}

