import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestAPIP } from '../Actions/Server';
import Loading from '../Components/Loading';
import Photo from './Photo';

import styles from './MediaBrowser.module.css';
import MediaItemBrowser from './MediaItemBrowser';
import AdminOnly from '../Containers/AdminOnly';
import TypedModal from '../Modals/TypedModal';
import CascadingThumbnails from './CascadingThumbnails';
import { Link } from 'react-router-dom';

// Separate type definition from 
// MediaTypes since endpoint/payloads are different
interface Folder {
  name: string;
  id: number;
  count: number;
  paths: Array<string>;
}

async function createFolder(name: string, dispatch: Dispatch<any>) {
  return requestAPIP({
    url: '/media/folders',
    method: 'POST',
    body: {
      foldername: name,
    }
  }, dispatch)
}

export default function MediaBrowser() {
  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState<Array<Folder> | null>(null);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState<boolean>(false);
  const [foldername, setFoldername] = useState('');

  const [fetchError, setFetchError] = useState<Error | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (fetchError == null && !isLoading && folders == null) {
      setIsLoading(true);
      const folders = requestAPIP({ url: '/media/folders' }, dispatch);
      folders.then(data => setFolders(data.folders)).finally(() => setIsLoading(false));
    }
  }, [isLoading, folders]);

  if (fetchError != null) {
    return <div>{fetchError.message}</div>
  }

  if (isLoading) {
    return <Loading>Loading media</Loading>;
  }

  return <div className={styles.root}>
    <section className={styles.folders}>
      <h2>Selected Media</h2>
      <AdminOnly>
        <div>
          <button onClick={() => setShowCreateFolderModal(true)}>Add folder</button>
        </div>
        {showCreateFolderModal && (
          <TypedModal
            onClose={() => setShowCreateFolderModal(false)}
            okText="Submit"
            onOk={async () => {
              const results = await createFolder(foldername, dispatch);
              if (!results.success) {
                return false;
              };
              setFolders([results.folder, ...(folders || [])]);
              return true;
            }}
            title="Create Folder"
          >
            <input
              type="text"
              value={foldername}
              onChange={event => setFoldername(event.target.value)} />
          </TypedModal>
        )}
      </AdminOnly>
      <div className={styles.foldersList}>
        {(folders || []).map(folder => {
          return <Link to={`/media/list/${folder.id}`} key={folder.id} style={{ textDecoration: 'none' }}>
            <div className={styles.folder}>
              <div className={styles.thumbnails}>
                <CascadingThumbnails paths={folder.paths} />
              </div>
              <div className={styles.folderFooter}>
                <div className={styles.folderName}>
                  {folder.name}
                </div>
                <div className={styles.folderCount}>
                  {folder.count ? `${folder.count} items` : 'Empty'}
                </div>
              </div>
            </div>
          </Link>
        })}
      </div>
    </section>
    <MediaItemBrowser
      renderImage={image =>
        <Photo className={styles.photo} key={image.id} image={image} />}
    />
  </div>
}
