import * as React from 'react';
import ButtonGroup from './ButtonGroup';
import ButtonStrip from './ButtonStrip';
import ButtonLink from './ButtonLink';
import loadingSVG from '../../PhotoViewer/black-loading.svg';
import './Button.css';

// type Props = {|
//   children: React.Node,
//   disabled?: boolean,
//   working?: boolean,
// |};

const WorkButton = ({ working, disabled, children, ...props }) => (
  <button disabled={disabled || working} {...props}>
    {children}
    {working ? (
      <img
        className="work-button-indicator"
        src={loadingSVG}
        role="presentation"
      />
    ) : null}
  </button>
);

export { WorkButton, ButtonGroup, ButtonStrip, ButtonLink };
