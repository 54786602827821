import React, { useEffect, useState } from 'react';

import AdminOnly from '../Containers/AdminOnly';
import { requestAPIP } from '../Actions/Server';
import { requestAPIP as requestAPIPSilently } from '../Lib/fetch';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AdminBrowser from './AdminBrowser';
import Loading from '../Components/Loading';
import { Folder, Photo as TPhoto } from './MediaTypes';
import Slideshow from '../Components/Slideshow';

import styles from './MediaFolder.module.css';
import Photo from './Photo';
import { produce } from 'immer';
import TypedModal from '../Modals/TypedModal';
import { ButtonLink } from '../Components/Button';
import PhotoList from '../Components/PhotoList';

interface RouteParams {
  folder: string,
}

function removePhotoFromFolder(folderID: number, photoID: number) {
  return requestAPIPSilently({
    url: '/media/delete_photos_from_folder',
    method: 'POST',
    body: {
      folderID,
      photoIDs: [photoID]
    }
  });
}

function deleteFolder(folderID: number) {
  return requestAPIPSilently({
    url: '/media/delete_folder',
    method: 'POST',
    body: {
      folderID
    }
  });
}

export default function MediaFolder() {
  const [folder, setFolder] = useState<Folder | null>(null);
  const [showAdder, setShowAdder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { folder: folderID } = useParams<RouteParams>();
  const match = useRouteMatch();
  const [activeSlide, setActiveSlide] = useState<null | number>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && folder == null) {
      setIsLoading(true);
      requestAPIP({ url: `/media/folder/${folderID}` }, dispatch)
        .then(response => {
          setFolder({
            ...response.folder,
            photos: response.photos,
          });
        }).finally(() => { setIsLoading(false) });
    }
  }, [isLoading]);
  if (isLoading) {
    return <Loading>Loading folder information</Loading>;
  }
  if (folder == null) {
    return <div>Failed to load folder information</div>;
  }
  return <div>
    <div className={styles.header}>
      <h1>{folder.name}</h1>
      <span>
        <button onClick={() => {
          setActiveSlide(0);
        }}>
          View slideshow
        </button>
        {activeSlide != null && (<Slideshow
          photo={folder.photos[activeSlide]}
          onLeft={() => setActiveSlide(Math.max(0, activeSlide - 1))}
          onRight={() => setActiveSlide(Math.min(folder.photos.length - 1, activeSlide + 1))}
          toClose={() => setActiveSlide(null)}
          paused={true}
        />)}
        <AdminOnly>
          <DeleteButton folderID={folder.id} />
          <ButtonLink to={match.url + '/reorder'} >Reorder</ButtonLink>
        </AdminOnly>
      </span>
    </div>
    {
      folder.photos.map((photo: any, index: number) =>
        <Thumbnail key={photo.id} photo={photo} folder={folder} setFolder={setFolder}
          onClick={() => setActiveSlide(index)}
        />)
    }
    <AdminOnly>
      <hr />
      <button onClick={() => setShowAdder(true)}>Add photos</button>
      {showAdder && (
        <AdminBrowser folder={folder} setFolder={setFolder} />
      )}
    </AdminOnly>
  </div >
}

function Thumbnail(props: {
  photo: TPhoto,
  folder: Folder,
  setFolder: (newValue: Folder) => void,
  onClick: () => void,
}) {
  const { photo, folder, setFolder } = props;
  const [isLoading, setIsLoading] = useState(false);

  return <div className={styles.thumbnail}>
    <AdminOnly>
      <button disabled={isLoading} onClick={() => {
        setIsLoading(true);
        removePhotoFromFolder(folder.id, photo.id)
          .then(() => {
            setFolder(produce(folder, folder => {
              const index = folder.photos.findIndex(p => p.id === photo.id);
              folder.photos.splice(index, 1);
            }))
          }).finally(() => {
            setIsLoading(false);
          })
      }}>X</button>
    </AdminOnly>
    <div onClick={props.onClick}>
      <Photo
        image={photo}
      />
    </div>
  </div>
}

function DeleteButton({ folderID }: { folderID: number }) {
  const history = useHistory();
  const [isModalShown, setIsModalShown] = useState(false);

  return <><button onClick={
    () => setIsModalShown(true)
  }>Delete folder</button>
    {isModalShown && (
      <TypedModal
        title="Delete folder"
        onClose={() => setIsModalShown(false)}
        onOk={async () => {
          await deleteFolder(folderID);
          history.push('/media');
          return true;
        }}>
        Are you sure you want to delete this folder?
      </TypedModal>
    )}
  </>
}