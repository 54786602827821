import React from 'react'

const Loading = ({isLoading, children}) => {
	return isLoading ? <img src="/images/loading.gif" role="presentation" /> : children;
}

Loading.defaultProps = {
	isLoading: true
}

export default Loading
