import React from 'react';
import Wrapper from '../Wrapper';
import SubscribeForm from './SubscribeForm';
import UnsubscribeForm from './UnsubscribeForm';

class SubscribeModal extends React.Component {
  render() {
    return (
      <Wrapper title="Subscribe/Unsubscribe" hideOk>
        <SubscribeForm />
        <UnsubscribeForm />
      </Wrapper>
    );
  }
}

export default SubscribeModal;
