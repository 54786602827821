import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authorize } from '../Actions/Gateway';

export default function GatewayForm(props: {
    ready: boolean,
    authorized: boolean,
}): React.ReactNode {
    const [value, setValue] = React.useState('');
    const dispatch = useDispatch();

    if (props.authorized && props.ready) {
        return <Redirect to="/" />;
    }

    return <>
        <h1>Access Key Required</h1>
        <form onSubmit={ev => {
            ev.preventDefault();
            dispatch(authorize(value));
        }}>
            <input type="text" value={value} onChange={ev => setValue(ev.target.value)} />
            <button type="submit">Enter</button>
        </form>
    </>
}
