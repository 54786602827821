import React from 'react'

const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const DateDisplay = (props) => {
	const date = new Date(props.value)
	const dateString = `${MONTH_NAMES[date.getMonth()]}  ${date.getDate()}, ${date.getFullYear()}  `
	return (
    <span className="date_display">
      {dateString}
    </span>
	);
}

