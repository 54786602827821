import {requestAPIX} from './Server';

import {uploadPhotoSuccess} from './Photos'

export const REQUEST_POSTS = 'POSTS_REQUEST'
export const RECEIVE_POSTS = 'POSTS_RECEIVE'
export const FETCH_ERROR = 'POST_FETCH_ERROR'
export const ADD_POST = 'POSTS_ADD'
export const POST_UPLOAD_SUCCESS = 'POSTS_UPLOAD_SUCCESS'
export const POST_UPLOAD_ERROR = 'POSTS_UPLOAD_ERROR'
export const POST_DELETE = 'delete a post'
export const POST_UPDATE_SUCCESS = 'successfully update a post'
export const POST_UPDATE_ERROR = 'failed to update a post'

const receivePosts = posts => ({type: RECEIVE_POSTS, posts});
const requestError = error => ({type: FETCH_ERROR, error});
const addPost = post => ({type: ADD_POST, post, success: 'Added new post'});

const fetchPostsBefore = upto => dispatch => {
	return dispatch(requestAPIX(`/posts/before/${upto}`))
		.val(posts => dispatch(receivePosts(posts)))
		.or(error => dispatch(requestError(error)));
}

export const fetchNextPage = () => (dispatch, getState) => {
	const posts = getState().posts.data;

	return dispatch(fetchPostsBefore(
		posts.length>0? new Date(posts[posts.length-1].start_date).valueOf() : Date.now()
	));
}


const updateSuccess = (post) => ({ 
	type: POST_UPDATE_SUCCESS, 
	post, 
	success: `Successfully edited post ${post.title}` 
});

const updateError = (error) => ({ type: POST_UPDATE_ERROR, error })

export const updatePost = post => dispatch=> {
	return dispatch(requestAPIX({
		url: '/edit_post',
		method: 'POST',
		body: post,
	})).val(() => {
		dispatch(updateSuccess(post));
		return post;
	}).or(error => updateError(error));
}

export const uploadPost = post => dispatch => {
	return dispatch(requestAPIX({
		url: '/upload_post',
		method: 'POST',
		body: post,
	}))
		.then((done, result) => {
			post.start_date = result.extra.start_date;
			post.id = result.extra.id;
			post.photos = [];

			dispatch(addPost(post));
			// add all uploaded photos

			if(result.extra.photos){
				if(!Array.isArray(result.extra.photos)) result.extra.photos = [result.extra.photos]
				result.extra.photos.forEach(photo=>{
					dispatch(uploadPhotoSuccess(photo))
				});
			}
			done();
		})
		.or(error => dispatch(handleUploadError(error, post)));
}

function handleUploadError(error, post){
	return {
		type: POST_UPLOAD_ERROR,
		error: `Failed to upload post: ${error.toString()}`,
		post
	}
}

const doDeletePost = post => ({type: POST_DELETE, post});

export const deletePost = post => (dispatch) => {
	return dispatch(requestAPIX(`/delete_post/${post.id}`))
		.val(() => dispatch(doDeletePost(post)))
		.val(true);
}
