import React from 'react'
import DOMPurify from 'dompurify'
import {markdown} from 'markdown';

const LineBreakText = ({ text }) => {
	if(!text) return null
	const linebreak = /\r?\n/g
	const brokenText = text.replace(linebreak, "<br />")
	const clean = DOMPurify.sanitize(brokenText)

	return (<p dangerouslySetInnerHTML={{ __html: clean}}></p>)
}

export const MarkdownText = ({ text }) => {
	if(!text) return null;
	const html = markdown.toHTML(text);
	return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default LineBreakText
