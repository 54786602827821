export const CRITICAL_ERROR = 'APP RUINING ERROR';
export const NETWORK_ERROR = 'ERROR QUERYING BACKEND';

export function networkError(error){
	return {
		type: NETWORK_ERROR,
		error
	}
}

export function criticalError(error){
	return {
		type: CRITICAL_ERROR,
		error,
		noNotify: true
	}
}
