import React from 'react';
import update from 'immutability-helper';
import fetchJSON from '../Lib/fetch';
import { imageAPI, API } from '../Config';
import './PictureUploader.css';
import loadingSVG from '../Modals/Loading/gear-small.svg';

class UploadPreview extends React.Component {
	state = { caption: '' };

	onChangeCaption = (e) => {
		this.setState({ caption: e.target.value })
	};

	submitCaption = () => {
		this.props.onSubmitCaption(this.props.id, {
			id: this.props.remoteID,
			description: this.state.caption
		})
	};

	render() {
		let { id, onDelete, src, state, submitCaptionState, uploadProgress } = this.props
		let tools = null
		let progressText = ''
		let spinner = (submitCaptionState !== 'idle') ? <img src={loadingSVG} role="presentation" /> : null;

		// only display if src exists
		if (!src) {
			return null;
		}

		if (state === 'uploaded') {
			tools = (<div className="preview_tools">
				<textarea value={this.state.caption} onChange={this.onChangeCaption} />
				<button
					onClick={this.submitCaption}
					disabled={submitCaptionState !== 'idle'}>
					Add Caption
				</button>
				<button
					onClick={() => onDelete({ remoteID: this.props.remoteID, previewID: this.props.id })}>
					Delete
				</button>
				{spinner}
			</div>)
		}
		else if (state === 'failed') {
			progressText = 'Failed to upload.'
		}
		else {
			progressText = (uploadProgress > 99) ? 'Processing' : uploadProgress
		}

		let classname = `${state} preview_thumb`;

		return (<div key={id} className="preview">
			<img className={classname} src={src} role="presentation" />
			<span className="upload_progress">{progressText}</span>
			{tools}
		</div>)
	}
}

class PictureUploader extends React.Component {
	state = { previews: [] };
	previewID = 0;

	onSubmitCaption = (localID, photo) => {
		photo.newAlbum = this.props.newAlbum

		this.setState(prevState => update(prevState, { previews: { [localID]: { submitCaptionState: { $set: 'saving' } } } }))

		this.props.saveCaption(photo)
			.then(done => {
				this.setState(update(this.state, { previews: { [localID]: { submitCaptionState: { $set: 'idle' } } } }))
				done()
			})
			.or(error => {
				this.setState(update(this.state, { previews: { [localID]: { submitCaptionState: { $set: 'idle' } } } }))
			})
	}

	deletePreview = ({ remoteID, previewID }) => {
		/**
			Delete photo from /delete_bank REST endpoint
		**/
		const { previews } = this.state;
		const { newAlbum } = this.props;
		if (newAlbum) {
			fetchJSON(new Request(API('/delete_bank'), {
				method: 'post',
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ id: remoteID }),
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}))
				.then((done, results) => {
					if (results.success === true) {
						let index = previews.findIndex(item => item.id === previewID)
						this.setState({
							previews: [
								...previews.slice(0, index),
								...previews.slice(index + 1)
							]
						})
					}
				})
		}
		else {
			/**
				Is an existing photo already saved on the server
				Use /delete_photo REST endpoint to delete
			**/
			fetchJSON(new Request(API('/delete_photo'), {
				method: 'post',
				mode: 'cors',
				credentials: 'include',
				body: JSON.stringify({ id: remoteID }),
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}))
				.then((done, results) => {
					if (results.success === true) {
						let index = this.state.previews.findIndex(item => item.id === previewID)
						this.setState({
							previews: [
								...this.state.previews.slice(0, index),
								...this.state.previews.slice(index + 1)
							]
						})
					}
				})
		}
	}

	render() {
		const { previews } = this.state;

		return (
			<div className="picture_uploader">
				<input type="file" className="file_button" onChange={this.handleChoose} ref={button => { this.fileButton = button }} multiple={true} />
				<button color="primary" size="sm" onClick={this.handleFakeButton}>Add Images</button>
				<div className="uploaded_progress">
					{previews.length ? <div className="previews">
						<h3>Uploaded Images</h3>
						{previews.map(image => <UploadPreview
							id={image.id}
							remoteID={image.remoteID}
							src={image.src}
							key={image.id}
							state={image.state}
							onDelete={this.deletePreview}
							submitCaptionState={image.submitCaptionState}
							onSubmitCaption={this.onSubmitCaption}
							uploadProgress={image.uploadProgress}
						/>)}
					</div> : null}
				</div>
			</div>)
	}

	handleFakeButton = (e) => {
		this.fileButton.click()
	};

	addError = errString => {
		this.setState({
			errors: [...this.state.errors, errString],
		});
	};

	handleChoose = e => {
		for (let i = 0; i < e.target.files.length; ++i) {
			const file = e.target.files[i];

			if (!/\.(jpe?g|png|mp4)$/i.test(file.name)) {
				this.addError(`${file.name} is not a valid upload`);
			}

			let payload = new FormData();

			if (/image/.test(file.type)) {
				payload.append('type', 'photo');
			} else if (/video/.test(file.type)) {
				payload.append('type', 'video');
			} else throw new Error('Invalid upload media type: ' + file.type);

			payload.append('photo', file);
			payload.append('isNewAlbum', this.props.newAlbum === true);
			payload.append('album', this.props.album);

			// add to previews
			let preview = {
				id: this.previewID++,
				state: 'uploading',
				submitCaptionState: 'idle',
				uploadProgress: 0,
			};

			if (payload.get('type') === 'photo') {
				this.setState(prevState =>
					update(prevState, { previews: { $push: [preview] } })
				);

				let reader = new FileReader();
				reader.onload = e => {
					const index = this.state.previews.findIndex(p => p.id === preview.id);
					this.setState(prevState =>
						update(prevState, {
							previews: {
								[index]: {
									src: {
										$set: e.target.result,
									},
								},
							},
						})
					);
				};
				reader.readAsDataURL(file);
			} else {
				preview.src = '/images/movie_placeholder.png';
				this.setState(prevState =>
					update(prevState, { previews: { $push: [preview] } })
				);
			}

			const onProgress = pEvent => {
				const index = this.state.previews.findIndex(p => p.id === preview.id);
				if (!pEvent.lengthComputable) return;
				this.setState(prevState =>
					update(prevState, {
						previews: {
							[index]: {
								uploadProgress: {
									$set: (pEvent.loaded / pEvent.total) * 100,
								},
							},
						},
					})
				);
			};

			this.props
				.submitPicture(payload, onProgress)
				// make preview show that preview succeeded
				.then((done, photo) => {
					const index = this.state.previews.findIndex(
						item => item.id === preview.id
					);
					let changes = {
						state: { $set: 'uploaded' },
						remoteID: { $set: photo.id },
					};
					if (photo.type === 'video') {
						changes.src = {
							$set: imageAPI.videoThumb('/albums/' + photo.original_path),
						};
					}
					this.setState(update(this.state, { previews: { [index]: changes } }));
					done();
				})
				.or(error => {
					const index = this.state.previews.findIndex(i => i.id === preview.id);
					this.setState(
						update(this.state, {
							previews: {
								[index]: {
									state: { $set: 'failed' },
									error: { $set: error },
								},
							},
						})
					);
				});
		}
	};
}

export default PictureUploader;
