import React from 'react';
import { connect } from 'react-redux';
import * as Actions from './Actions';
import { WorkButton } from '../Components/Button';
import ASQ from 'asynquence';
import './Wrapper.css';

class Wrapper extends React.Component {
  closeOnEscape = e => {
    if (e.key === 'Escape') {
      this.props.closeModal();
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.closeOnEscape);
    // kinda hacky, find some way to change body or app
    document.querySelector('body').classList.add('no-scroll');
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeOnEscape);
    document.querySelector('body').classList.remove('no-scroll');
  }

  handleBGClick = e => {
    if (e.target === e.currentTarget) this.props.closeModal();
  };

  onOk = () => {
    if (!this.props.onOk) {
      this.props.closeModal();
      return;
    }
    const onOkResult = this.props.onOk();
    if (typeof onOkResult === 'boolean') {
      if (onOkResult === true) this.props.closeModal();
    } else if (ASQ.isSequence(onOkResult)) {
      onOkResult.val(result => {
        if (result === true) this.props.closeModal();
      });
    }
  };

  onEnter = event => {
    event.preventDefault();
    event.stopPropagation();

    this.onOk();
  };

  render() {
    const {
      okDisabled,
      okText,
      hideOk,
      className,
      width,
      title,
      children,
      closeModal,
      closeText,
      working,
      isForm,
    } = this.props;

    const okButton = hideOk ? null : (
      <WorkButton onClick={this.onOk} disabled={okDisabled} working={working}>
        {okText}
      </WorkButton>
    );

    return (
      <div className="modal_backdrop" onClick={this.handleBGClick}>
        <div
          style={{ width: width || '50%' }}
          className={`modal_container ${className}`}
        >
          <header>
            <h1>{title}</h1>
          </header>
          <div className="modal_body">
            {isForm ? (
              <form onSubmit={this.onEnter}>{children}</form>
            ) : (
              children
            )}
          </div>

          <footer>
            {okButton}
            <button onClick={closeModal} color="danger" size="sm">
              {closeText || 'Close'}
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

Wrapper.defaultProps = {
  onOk: () => {},
  okDisabled: false,
  hideOk: false,
  okText: 'Ok',
  className: '',
};

export default connect(
  null,
  Actions
)(Wrapper);
