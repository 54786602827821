import React from 'react';
import './Dropdown.css';

const Dropdown = ({ children, className }) => (
  <div className={`ey-dropdown ${className || ''}`}>{children}</div>
);

export const DropdownLabel = props => (
  <span className="ey-dropdown-label">{props.children}</span>
);
export const DropdownMenu = props => <ul>{props.children}</ul>;
export const DropdownItem = ({ onClick, children }) => (
  <li>
    <a className="ey-dropdown-item" href="#" onClick={onClick}>
      {children}
    </a>
  </li>
);

export default Dropdown;
