import React from "react";
import {Link} from 'react-router-dom';
import { DateDisplay } from "./Date";
import AdminOnly from "../Containers/AdminOnly";
import { PhotoStrip } from "./PhotoStrip";
import { MarkdownText } from "./LineBreakText";
import Dropdown, {
  DropdownLabel,
  DropdownMenu,
  DropdownItem,
} from "./Dropdown";
import { ButtonLink } from "./Button";
import RichText from './RichText';
import "./PostView.css";

class PostView extends React.Component {
  deletePost = () => {
    const { deletePost, id, title, description } = this.props;
    deletePost({ id, title, description });
  };

  editPost = () => {
    const { editPost, id, title, description, start_date } = this.props;
    editPost({ id, title, description, postDate: start_date });
  };

  render() {
    const { photos, description, title, id, start_date } = this.props;
    return (
      <div className="post" key={id}>
        <header>
          <h1 className="title">
            {title}
            <ButtonLink className="goto-album-button" to={`/album/${id}`}>
              View
            </ButtonLink>
            <AdminOnly>
              <Dropdown className="admin-tools">
                <DropdownLabel>Admin Options</DropdownLabel>
                <DropdownMenu>
                  <DropdownItem onClick={this.editPost}>Edit</DropdownItem>
                  <DropdownItem onClick={this.deletePost}>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </AdminOnly>
          </h1>
          <DateDisplay value={start_date} />
        </header>
        <RichText text={description} />
				{photos != null && photos.length > 0 && (
          <Link className="photostrip-link" to={`/album/${id}`}>
					  <PhotoStrip photos={photos} />
          </Link>
				)}
      </div>
    );
  }
}

export default PostView;
