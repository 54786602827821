import React from 'react';
import { imageAPI } from '../Config';
import './style.css';

export default ({ onLoad, onPlay, video, minimal, noPlayOnClick = false }) => {
  const src = imageAPI.video(video.original_path);
  const thumbnail = imageAPI.videoThumb(video.original_path);
  const filename = src.match(/\/?([^/]+$)/)[1];
  const desc = video.description;

  return (
    <div className="video-viewer">
      {!minimal && <p className="id_header">{decodeURIComponent(filename)}</p>}
      <video
        onLoadStart={onLoad}
        onPlay={onPlay}
        controls
        id={`video-${video.id}`}
        className="video-js"
        poster={thumbnail}
        aria-label={video.description}
      >
        <source src={src} type="video/mp4" />
      </video>
      {!minimal && !!desc && <p>{desc}</p>}
    </div>
  );
};
