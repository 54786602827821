import { requestAPIX } from './Server';
import { networkError } from './Error';

export const LOAD_RESULT = 'LOAD GATEWAY CHECK RESULT';

const loadResult = (results, notify) => { 
	const action = {
		type: LOAD_RESULT, 
    authorized: results.authorized,
    canAdmin: results.canAdmin,
	}

	if(notify){
		if(results.authorized) action.success = 'Successfully authorized';
		else action.error = 'Failed to authorize';
	}
// const loadResult = (authorized, notify) => {
//   const action = {
//     type: LOAD_RESULT,
//     authorized
//   }

//   if (notify) {
//     if (authorized) action.success = 'Successfully authorized';
//     else action.error = 'Failed to authorize';
//   }

  return action;
};

export const fetchGatewayInfo = () => dispatch => {
	return dispatch(requestAPIX('/check'))
		.val(json => dispatch(loadResult(json)))
		.or(error => dispatch(networkError(error)));
}


export const authorize = key => dispatch => {
	return dispatch(requestAPIX(`/check/${key}`))
		.val(json => dispatch(loadResult(json, true)))
		.or(error => dispatch(networkError('Could not authorize with that key')));
}
