export const NOTIFICATION_ADD = 'add notification'
export const NOTIFICATION_REMOVE = 'close notification'

let noteID = 0

export function addNotification(note){
	if(typeof note === 'string'){
		note = {message: note}
	}
	return function(dispatch){
		const expiration = note.expiration || 5000
		note.id = noteID++

		note.timeout = setTimeout(()=>{
			dispatch(closeNotification(note))
		}, expiration);

		return dispatch({
			type: NOTIFICATION_ADD,
			note
		})
	}
}

export function closeNotification(note){
	clearTimeout(note.timeout)
	return {
		type: NOTIFICATION_REMOVE,
		note
	};
}
