import App from './App.js';
import {connect} from 'react-redux';
import {fetchGatewayInfo} from './Actions/Gateway';
import {checkAuthenticate} from './Actions/Admin';

const mapStateToProps = (state, props) => ({
    gateway: state.gateway,
});

const mapDispatchToProps = {fetchGatewayInfo, checkAuthenticate};

export default connect(mapStateToProps, mapDispatchToProps)(App);