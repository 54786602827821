import React from 'react';

import { imageAPI } from '../Config';

import styles from './CascadingThumbnails.module.css';

export default function CascadingThumbnails(
  { paths }: {
    paths: Array<string>
  }
) {
  return <div className={styles.container}>
    <Thumbnails paths={paths.slice(0, 3)} index={0} />
  </div>
}

function Thumbnails({ index, paths }: {
  paths: Array<string>,
  index: number
}) {
  if (index >= paths.length) {
    return null;
  }
  // Hacky, but dont have video/photo information
  let image;
  if (/(mp4|avi)/.test(paths[index])) {
    image = <div>
      <img className={styles.thumbnail} src={imageAPI.videoThumb(paths[index])} />
    </div>;
  } else {
    image = <img className={styles.thumbnail} src={imageAPI.thumb(paths[index])} />
  }
  return <div style={{
    zIndex: (index * -1),
    position: 'absolute',
    top: `12px`,
    left: `12px`,
  }}>
    {image}
    <Thumbnails paths={paths} index={index + 1} />
  </div>
}
