import React from 'react'
import Wrapper from '../Wrapper'
import {openModal} from '../Actions'
import './style.css';

class EditTextModal extends React.Component{
	state = {};

	componentDidMount(){
		this.setState({ value: this.props.options.value })
    this.input.focus()
  }

	render(){
		let {options, ...props} = this.props
		return (<Wrapper
			title={options.title || props.title}
			onOk={() => options.onOk(this.state.value)}
			okText="Save"
			{...props}
      className="edit_text_modal">
			<textarea 
				type="text" 
				value={this.state.value || ''} 
				ref={e => this.input = e} 
				onChange={e => this.setState({value: e.target.value})} 
				className="input" />
		</Wrapper>)
	}
}

export const openEditTextModal = (options) => openModal(EditTextModal, options);

export default EditTextModal
