import React from 'react';
import { ButtonStrip } from "../Components/Button";
import { useSelector } from 'react-redux';

interface Props {
	subscriptionGroups: Array<{
		id: number,
		name: string,
	}>,
	onChange: any,
}

export default function SubscriptionPicker({onChange}: Props) {
	const groups = useSelector((state: any) => state.subscriptions.groups || []);
	return (<div>
		<h4>Send Notifications to</h4>
		<ButtonStrip options={groups.map((group: any) => ({
			id: group.id,
			description: group.name
		}))} onChange={onChange} />
	</div>
	);
}

