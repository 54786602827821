import React, { SyntheticEvent, useEffect } from 'react';
import { WorkButton } from '../Components/Button';
import './Wrapper.css';

interface Props {
  children: React.ReactNode,
  title: string,
  onClose: () => void,
  onOk?: () => boolean | Promise<boolean>,
  okDisabled?: boolean,
  okText?: string,
  hideOk?: boolean,
  className?: string,
  width?: number,
  closeText?: string,
  working?: boolean,
  isForm?: boolean,
}

export default function TypedModal(props: Props) {
  const handleEscapePress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    }
  });

  const handleBGClick = (e: SyntheticEvent) => {
    if (e.target === e.currentTarget) {
      props.onClose();
    }
  };

  const onOk = () => {
    if (!props.onOk) {
      props.onClose();
      return;
    }
    const onOkResult = props.onOk();
    if (typeof onOkResult === 'boolean') {
      if (onOkResult) props.onClose();
    } else {
      onOkResult.then(result => {
        if (result) props.onClose();
      });
    }
  };

  const onEnter = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onOk();
  };

  const {
    okDisabled,
    okText = 'Confirm',
    hideOk,
    className,
    width,
    title,
    children,
    onClose,
    closeText,
    working,
    isForm,
  } = props;

  const okButton = hideOk ? null : (
    <WorkButton onClick={onOk} disabled={okDisabled} working={working}>
      {okText}
    </WorkButton>
  );

  return (
    <div className="modal_backdrop" onClick={handleBGClick}>
      <div
        style={{ width: width || '50%' }}
        className={`modal_container ${className}`}
      >
        <header>
          <h1>{title}</h1>
        </header>
        <div className="modal_body">
          {isForm ? (
            <form onSubmit={onEnter}>{children}</form>
          ) : (
            children
          )}
        </div>

        <footer>
          {okButton}
          <button onClick={onClose} color="danger">
            {closeText || 'Close'}
          </button>
        </footer>
      </div>
    </div>
  );
}
