import React, {Component} from 'react';
import {ResizeViewer} from '../PhotoViewer';
import AdminOnly from '../Containers/AdminOnly';
import {connect} from 'react-redux';
import {deletePhoto, rotate, editPhoto} from '../Actions/Photos';
import {openEditTextModal} from "../Modals/EditText"
import VideoViewer from "../VideoViewer/index";
import Dropdown, {DropdownLabel, DropdownMenu, DropdownItem} from './Dropdown';
import './PhotoList.css';

/***
Photo Item component
Wraps photo viewer so that showPhoto can be clicked
***/
class Photo extends Component{
	static defaultProps = {
		showTools: true
	};

  handleEditCaption = () => {
		const {openEditTextModal, editPhoto, photo} = this.props;

    openEditTextModal({
      title: 'Edit Caption for Photo ' + photo.id,
      value: photo.description,
			onOk: value => {
				let newPhoto = {...photo, description: value};
				return editPhoto(newPhoto).val(true);
			}
    })
  };

  determineMediaType(path){
		if(/\.(mp4|m4v)$/i.test(path))
			return 'video';
		else if(/\.(png|jpe?g)$/i.test(path))
			return 'photo';
		else
			return 'unknown';
  }

	render(){
    let body;
		const {photo, isSelected, showTools, index, showPhoto, deletePhoto, rotatePhoto} = this.props;
    switch(this.determineMediaType(photo.original_path)){
      case 'photo':
				body = <ResizeViewer handleClick={() => showPhoto(index)} showId={true} photo={photo} />;
        break;
      case 'video':
        body = <VideoViewer video={photo} />;
        break;
      default:
			body = null;
			break;
	}
    return (<div className={'wrapper' + (isSelected ? " selected" : "")}>
      {body}
      {showTools ? 
          <AdminOnly>
            <Dropdown>
              <DropdownLabel>Admin Options</DropdownLabel>
              <DropdownMenu>
                <DropdownItem onClick={this.handleEditCaption}>Edit Caption</DropdownItem>
                <DropdownItem onClick={()=>rotatePhoto(photo.id, 270)}>Rotate CounterClockwise</DropdownItem>
                <DropdownItem onClick={()=>rotatePhoto(photo.id, 90)}>Rotate Clockwise</DropdownItem>
                <DropdownItem onClick={()=>deletePhoto(photo.id)}>Delete</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </AdminOnly>
          : null 
      }
    </div>);
  }
}

/***
Expects array of photos, each of which should have
  - id
  - original_path
  - alt tag
- Callback for showPhoto
 ***/
class PhotoListBase extends Component{
  state = {
    numShown: 9
  };

  static defaultProps = {
    photos: [],
  };

  render(){
    const {photos, showPhoto, deletePhoto, rotatePhoto, editPhoto, openEditTextModal} = this.props;

    const photoEls = photos.slice(0, this.state.numShown).map((photo, index) => <Photo
      photo={photo} key={index} index={index}
      showPhoto={showPhoto} deletePhoto={deletePhoto} rotatePhoto={rotatePhoto}
      editPhoto={editPhoto} openEditTextModal={openEditTextModal}
    />);
    return <div className="photo_list">{photoEls}</div>;
  }

  componentDidMount(){
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = ev => {
    if(this.state.numShown < this.props.photos.length && (window.innerHeight+window.scrollY) >= document.body.offsetHeight){
      this.setState({numShown: this.state.numShown+6});
    }
  }
}

export default connect(null, {deletePhoto, rotatePhoto: rotate, editPhoto, openEditTextModal})(PhotoListBase);
