import {connect} from 'react-redux'

// wrapper component
const AdminOnly = ({inverse, isAdmin, children }) => {
	return(!inverse === isAdmin) ? children : null;
};

function mapStateToProps(state, ownProps){
	return {
		isAdmin: state.isAdmin,
		inverse: ownProps.inverse === true
	}
}

export default connect(mapStateToProps)(AdminOnly)
