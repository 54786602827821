import {combineReducers} from 'redux';
import {LOAD_SUBSCRIPTIONS, LOAD_SUBSCRIPTION_GROUPS, REMOVE_SUB_FROM_GROUP, ADD_SUB_TO_GROUP, DELETE_GROUP, CREATE_GROUP, CREATE_SUB, TOGGLE_SUB_ENABLED} from '../Actions/Subscriptions';
import update from 'immutability-helper';

export const groups = (state = [], action) => {
  switch(action.type){
    case LOAD_SUBSCRIPTION_GROUPS:
      return action.groups;
		case CREATE_GROUP:
			return update(state, {$push: [{
			  id: action.id,
        name: action.name
			}]});
		case DELETE_GROUP:
			let index = state.findIndex(i => i.id === action.group.id);
			return update(state, {
				$splice: [[index, 1]]
			});
    default:
      return state
  }
};

export const data = (state = [], action) => {
  if (action.type === LOAD_SUBSCRIPTIONS) {
    return action.subs;
  }
  else if(action.type === CREATE_SUB){
    return update(state, {
      $push: [action.sub]
    });
  }
  else if(action.type === TOGGLE_SUB_ENABLED){
    const index = state.findIndex(sub => sub.id === action.id);
    return update(state, {
      [index]: {
        enabled: {
          $set: action.enabled
        }
      }
    })
  }
  else if (action.type === REMOVE_SUB_FROM_GROUP || action.type === ADD_SUB_TO_GROUP) {
    const index = state.findIndex(function (sub) {
      return sub.id === action.sub;
    });
    let excluded = Object.assign({}, state[index].groups);
    delete excluded[action.group];
    if (action.type === REMOVE_SUB_FROM_GROUP) {
      return update(state, {
        [index]: {
          groups: {
            $set: excluded
          }
        }
      });
    }
    else {
      return update(state, {
        [index]: {
          groups: {
            $merge: {
              [action.group]: true
            }
          }
        }
      });
    }
  }
  return state;
};



export const isFetching = (state = false, action) => {
	return state;
};

export const subscriptions = combineReducers({groups, data, isFetching})
